//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import {
  CreateMovies,
  CreateSeries,
  EditLivesRequest,
  EditMoviesRequest,
  GetCategoriesByType,
  GetSubCategoriesByCategory,
  GetSubCategoriesLivesCount,
  GetSubCategoriesMoviesCount,
  GetSubCategoriesSeriesCount,
  UpdateServerAPI,
} from "../Assets/HttpBank";

const EditServer = (prop) => {
  const [IP, setIP] = useState(prop?.ServerToEdit?.IP);
  const [PORT, setPORT] = useState(prop?.ServerToEdit?.PORT);
  const [Name, setName] = useState(prop?.ServerToEdit?.Name);
  const [Enabled, setEnabled] = useState(prop?.ServerToEdit?.enabled);

  const Update = async () => {
    prop?.Message?.({ Message: "Updating Server...", Type: "info" });
    const CreatedMovies = await UpdateServerAPI({
      ID: prop?.ServerToEdit?._id,
      Name,
      IP,
      PORT,
      enabled: Enabled,
    });

    if (!CreatedMovies?.Valid) {
      prop?.Message?.({
        Message: "Couldn't update the Server.",
        Type: "error",
      });
      return;
    }

    prop?.data?.(CreatedMovies?.data);

    prop?.Message?.({
      Message: `Server updated!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add server.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={"text"}
          value={Name}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Server Name"}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={"text"}
          value={IP}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Server IP"}
          onChange={(e) => {
            setIP(e.target.value);
          }}
        />
      </div>

      <Input
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginLeft: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        value={PORT}
        placeholder={"PORT - (Default is 3008)"}
        onChange={(e) => {
          setPORT(e.target.value);
        }}
      />

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Enabled?</InputLabel>
        <Select
          onChange={(e) => {
            setEnabled(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Enabled}

          // onChange={handleChange}
        >
          {[
            { Name: "Enabled", Value: true },
            { Name: "Disabled", Value: false },
          ].map((Item) => {
            return <MenuItem value={Item.Value}>{Item.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Update();
        }}
      >
        <h5>Update</h5>
      </Button>
    </Card>
  );
};

export default EditServer;
