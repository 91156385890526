//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import {
  CreateMovies,
  CreateSeries,
  GetCategoriesAndCount,
  GetCategoriesByType,
  GetSubCategoriesByCategory,
  GetSubCategoriesMoviesCount,
  GetSubCategoriesSeriesCount,
} from "../Assets/HttpBank";

const AddMovies = (prop) => {
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  const [Picture, setPicture] = useState("");
  const [Url, setUrl] = useState("");
  const [SubCategories, setSubCategories] = useState([]);
  const [Featured, setFeatured] = useState(false);
  const [SubCategory, setSubCategory] = useState({});
  const [Categories, setCategories] = useState([]);
  const [Category, setCategory] = useState({});

  useEffect(() => {
    (async () => {
      const Data = await GetSubCategoriesMoviesCount({});
      const SubCategories = Data?.SubCategories ? Data?.SubCategories : [];
      setSubCategories([...SubCategories]);
      if (SubCategories[0]) {
        setSubCategory(SubCategories[0]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Data = await GetSubCategoriesByCategory({
        CategoryID: `${Category?._id}`,
      });
      const SubCategories = Data?.SubCategories ? Data?.SubCategories : [];
      setSubCategories([...SubCategories]);
      if (SubCategories[0]) {
        setSubCategory(SubCategories[0]);
      }
    })();
  }, [Category]);

  useEffect(() => {
    (async () => {
      const Data = await GetCategoriesByType("Movies");
      const Categories = Data?.Categories ? Data?.Categories : [];
      setCategories([...Categories]);
      if (Categories[0]) {
        setCategory(Categories[0]);
      }
    })();
  }, []);

  const Create = async () => {
    prop?.Message?.({ Message: "Creating Movie...", Type: "info" });
    const CreatedSeries = await CreateMovies({
      Name,
      Picture,
      SubCategory,
      Featured,
      Url,
      Category,
    });

    if (!CreatedSeries?.Valid) {
      prop?.Message?.({
        Message: "Couldn't create the Movie.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `Movie created!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Movie.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={"text"}
          value={Name}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Movie name"}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <Input
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginLeft: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        value={Picture}
        placeholder={"Picture Link"}
        onChange={(e) => {
          setPicture(e.target.value);
        }}
      />

      <Input
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginLeft: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        value={Url}
        placeholder={"Movie Link"}
        onChange={(e) => {
          setUrl(e.target.value);
        }}
      />

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Sub Category?</InputLabel>
        <Select
          onChange={(e) => {
            setSubCategory(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={SubCategory}
          label="Age"
          // onChange={handleChange}
        >
          {SubCategories.map((SubCategory) => {
            return <MenuItem value={SubCategory}>{SubCategory?.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Category?</InputLabel>
        <Select
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Category}
          label="Age"
          // onChange={handleChange}
        >
          {Categories.map((Category) => {
            return <MenuItem value={Category}>{Category?.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Featured?</InputLabel>
        <Select
          onChange={(e) => {
            setFeatured(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Featured}
          label="Age"
          // onChange={handleChange}
        >
          {[
            { Name: "Featured", Value: true },
            { Name: "Not Featured", Value: false },
          ].map((Item) => {
            return <MenuItem value={Item.Value}>{Item.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Create();
        }}
      >
        <h5>Create</h5>
      </Button>
    </Card>
  );
};

export default AddMovies;
