//New react functional component
import { Fragment, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
  IconButton,
  Collapse,
  Box,
} from "@mui/material";
import { DeletePeriod, DeleteSubCategory } from "../Assets/HttpBank";
import { Delete, Edit, FolderOpen } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function createData(name, index) {
  return { name, index };
}

const UserItem = (prop) => {
  const { row, RefreshCompInside, onDelete, onEdit, canDelete, canEdit } = prop;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row?.Username}</TableCell>
        <TableCell align="left">{row?.CurrentBalance}</TableCell>
        <TableCell align="left">{row?.StartedBalance}</TableCell>
        <TableCell align="left">{row?.SeriesCount}</TableCell>
        <TableCell align="left">{row?.EpisodesCount}</TableCell>
        <TableCell align="left">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {canEdit && (
              <CardActionArea
                onClick={() => {
                  onEdit?.(row._id);
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Edit style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}

            {canDelete && (
              <CardActionArea
                onClick={() => {
                  onDelete?.();
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Delete style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Privileges
              </Typography>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Navigation
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Navigation?.map?.(
                              (Item, index) => {
                                return (
                                  <TableCell key={index}>
                                    {Item?.Name}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Navigation?.map?.(
                              (Item, index) => {
                                return (
                                  <TableCell
                                    key={index}
                                    component="th"
                                    scope="row"
                                  >
                                    {Item?.permitted || row?.isAdmin
                                      ? `Yes`
                                      : "No"}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Delete
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Delete?.map?.((Item, index) => {
                              return (
                                <TableCell key={index}>{Item?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Delete?.map?.((Item, index) => {
                              return (
                                <TableCell component="th" scope="row">
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Edit
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Edit?.map?.((Item, index) => {
                              return (
                                <TableCell key={index}>{Item?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Edit?.map?.((Item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  component="th"
                                  scope="row"
                                >
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Upload
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Upload?.map?.((row, index) => {
                              return (
                                <TableCell key={index}>{row?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Upload?.map?.((Item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  component="th"
                                  scope="row"
                                >
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Generate
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Generate?.map?.((row, index) => {
                              return (
                                <TableCell key={index}>{row?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Generate?.map?.((Item, index) => {
                              return (
                                <TableCell component="th" scope="row">
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Manual
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Manual?.map?.((row, index) => {
                              return (
                                <TableCell key={index}>{row?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Manual?.map?.((Item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  component="th"
                                  scope="row"
                                >
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Export
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Export?.map?.((row, index) => {
                              return (
                                <TableCell key={index}>{row?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Export?.map?.((Item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  component="th"
                                  scope="row"
                                >
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Create
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            {row?.Privileges?.Create?.map?.((row, index) => {
                              return (
                                <TableCell key={index}>{row?.Name}</TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {/* {row?.history?.map?.((historyRow) => ( */}
                          <TableRow>
                            {row?.Privileges?.Create?.map?.((Item, index) => {
                              return (
                                <TableCell
                                  key={index}
                                  component="th"
                                  scope="row"
                                >
                                  {Item?.permitted || row?.isAdmin
                                    ? `Yes`
                                    : "No"}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {/* ))} */}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default UserItem;
