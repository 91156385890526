import { Tooltip } from '@mui/material';
import React from 'react';

const ServerStatus = () => {
    const styles = {
        container: {
            width: '100%',
            paddingLeft: '10px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            gap: '15%',
            backgroundColor: 'white',
        },
        serverStatus: {
            borderLeftWidth: '5px',
            height: '40px',
            fontWeight: 'bold',
            paddingLeft: '5px',
            paddingRight: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'help',
        },
        healthy: {
            borderLeftColor: 'green',
            color: 'green',
        },
        busy: {
            borderLeftColor: 'orange',
            color: 'orange',
        },
        outage: {
            borderLeftColor: 'red',
            color: 'red',
        },
        down: {
            borderLeftColor: 'black',
            color: 'black',
        },
    };

    return (
        <div style={styles.container}>

            <div style={{ ...styles.serverStatus, ...styles.healthy }}>
                <div style={{ width: 6, borderRadius: 10, height: "100%", background: "green" }} />
                <Tooltip
                    title="Green indicates that the server is in good health and is functioning normally."
                    style={{ marginLeft: 5 }}
                >

                    <h5>Healthy</h5>

                </Tooltip>
            </div>

            <div style={{ ...styles.serverStatus, ...styles.busy }}>
                <div style={{ width: 6, borderRadius: 10, height: "100%", background: "orange" }} />
                <Tooltip
                    title="Orange indicates that the server is operating in moderate mode, somewhere between comfortably and busy."
                    style={{ marginLeft: 5 }}
                >
                    <h5>Busy</h5>
                </Tooltip>
            </div>


            <div style={{ ...styles.serverStatus, ...styles.outage }}>
                <div style={{ width: 6, borderRadius: 10, height: "100%", background: "red" }} />
                <Tooltip
                    title="The color red indicates that the server is in outage mode, is extremely busy, and may be in danger."
                    style={{ marginLeft: 5 }}
                >
                    <h5>Outage</h5>
                </Tooltip>
            </div>

            <div style={{ ...styles.serverStatus, ...styles.down }}>
                <div style={{ width: 6, borderRadius: 10, height: "100%", background: "black" }} />
                <Tooltip
                    title="The color black indicates that the server is offline and cannot stream or accept any requests."
                    style={{ marginLeft: 5 }}
                >
                    <h5>Down</h5>
                </Tooltip>
            </div>
        </div>
    );
};

export default ServerStatus;