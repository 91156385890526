//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  GetLivesAndCount,
  GetLivesAndCountSearch,
  GetMoviesAndCount,
  GetMoviesAndCountSearch,
  GetSeriesAndCount,
  GetSeriesAndCountSearch,
  GetServers,
  GetSettings,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import MoviesItem from "./MoviesItem";
import LivesItem from "./LivesItem";

import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import socketio from "socket.io-client";
import ServerItem from "./ServerItem";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";
import ServerStatus from "./ServerStatus";

function createData(name, index) {
  return { name, index };
}

const ServersTable = (prop) => {
  const {
    RefreshComp,
    onEdit,
    canStatus,
    Search,
    canDelete,
    canEdit,
    onCounts,
    Servers,
    ServersData,
  } = prop;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
    }, 100);
  }, []);

  const [Lives, setLives] = useState([]);

  useEffect(() => {
    if (ServersData?.length >= 1) {
      setLives(ServersData);
    }
  }, [ServersData]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const RefreshSeries = async () => {
    // if (NumberOfRows >= 1) {
    console.time("GetLivesAndCount");
    const DataServers = await GetServers();
    console.timeEnd("GetLivesAndCount");

    const { data = [], Valid } = DataServers ? DataServers : {};
    console.warn(data);
    if (!Valid) return;

    // onCounts?.(OfflineCount, OnlineCount);
    setLives(data);
    // setCount(Count);
    // }
  };

  useEffect(() => {
    // if (Search?.length <= 0) {
    // console.log("Requesting Lives...");
    RefreshSeries();
    // }
  }, [RefreshComp, RefreshCompInside]);

  return (
    <TableContainer component={Paper}>
      <ServerStatus />

      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            {/* <TableCell>Picture</TableCell> */}
            <TableCell>Name</TableCell>
            {/* <TableCell>Views</TableCell> */}
            <TableCell>Connections</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{ opacity: Lives.length <= 0 ? 0 : 1, transition: "all .6s" }}
        >
          {Lives.map((row, index) => {
            return (
              <ServerItem
                canDelete={canDelete}
                canEdit={canEdit}
                canStatus={canStatus}
                Servers={Servers}
                key={index}
                row={row}
                RefreshCompInside={(_id) => {
                  if (_id) {
                    const NewServers = Lives.filter(
                      (server) => `${server?._id}` != `${_id}`
                    );

                    setLives(NewServers);
                  } else {
                    setRefreshCompInside(!RefreshCompInside);
                  }
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>

      <div
        style={{
          width: "100%",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",

          padding: 10,
        }}
      >
        {/* <TablePagination
          component="div"
          // count={Count}
          // page={Page}
          // onPageChange={(c, page) => {
          //   setPage(page);
          //   setSkip(page * NumberOfRows);
          //   //   alert(page);
          // }}
          // rowsPerPage={5000}
          // rowsPerPageOptions={[5000]}
        /> */}
      </div>
    </TableContainer>
  );
};

export default ServersTable;
