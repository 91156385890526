//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { GetCategoriesAndCount, GetSettings } from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function createData(name, index) {
  return { name, index };
}

const CategoriesTable = (prop) => {
  const { RefreshComp, onEdit, canDelete, canEdit } = prop;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Categories, setCategories] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetCategoriesAndCount(Skip, NumberOfRows);
        const { Count, Categories, Valid } = Data ? Data : {};
        if (!Valid) return;
        setCategories(Categories);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside]);

  return (
    <TableContainer
      component={Paper}
      style={{
        maxHeight: "100%",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Logo</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: Categories.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {Categories.map((row, index) => {
            return (
              <CategoriesItem
                canDelete={canDelete}
                canEdit={canEdit}
                key={index}
                row={row}
                RefreshCompInside={() => {
                  setRefreshCompInside(!RefreshCompInside);
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>

      <div
        style={{
          width: "100%",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",

          padding: 10,
        }}
      >
        <TablePagination
          component="div"
          count={Count}
          page={Page}
          onPageChange={(c, page) => {
            setPage(page);
            setSkip(page * NumberOfRows);
            //   alert(page);
          }}
          rowsPerPage={NumberOfRows}
          rowsPerPageOptions={[6]}
        />
      </div>
    </TableContainer>
  );
};

export default CategoriesTable;
