//Create Navigation for the react router
import { createRef, useEffect, useRef, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";

import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import { GetPrivileges, UploadSubCategoriesWithData, ValidateToken } from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import { Add, CloudUpload, Details, Upload } from "@mui/icons-material";
import AddCode from "../Components/AddCode";
import EditCode from "../Components/EditCode";
import SeriesTable from "../Components/SeriesTable";
import AddSeries from "../Components/AddSeries";
import EditSeries from "../Components/EditSeries";
import SubCategoriesTable from "../Components/SubCategoriesTable";
import AddSubCategory from "../Components/AddSubCategory.js";
import EditSubCategory from "../Components/EditSubCategory";

const SubCategories = (prop) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/SubCategories");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [SubCategoryToEdit, setSubCategoryToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canExport, setCanExport] = useState(false)

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();

      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
        setCanExport(true)
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Sub Categories"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Sub Categories"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Sub Categories"
        );

        setCanDelete(GetDelete[0]?.permitted);


        const GetExport = Privileges?.Export?.filter?.(
          (item) => item.Name === "Sub Categories"
        );

        setCanExport(GetExport[0]?.permitted);
      }
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  //Init Input for file, to use in the render
  const InputFile = createRef();

  // const [FileValue, setFile] = useState({});


  const readFile = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      const SubCategory = JSON.parse(reader.result);
      const InsertSubCategories = await UploadSubCategoriesWithData({ SubCategory })

      const { message = "", Valid } = InsertSubCategories

      if (message && !Valid) {
        setMessage({ Message: message, Type: "error" })
      } else if (!Valid) {
        setMessage({ Message: "Not valid, please check the file format, or the server status.", Type: "error" })
      } else {
        setMessage({ Message: "The sub category has been uploaded and everything looks perfect.", Type: "success" })
        console.log(InsertSubCategories);

        setRefreshComp(!RefreshComp);
      }
    }
  }


  const [WatchingNow, setWatchingNow] = useState(0);

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddSubCategory
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditSubCategory
            SubCategoryToEdit={SubCategoryToEdit}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <CardActionArea
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 15,
                width: "auto",
                padding: 10,
                height: "auto",
                borderRadius: 10,
              }}
            >
              <h2
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
              >
                Sub Categories ~
              </h2>
              <h2
                style={{
                  color: "red",
                  marginLeft: 5,
                  transition: "color 0.5s ease-in-out",
                  animation: `${WatchingNow > 0 ? "blink" : "none"
                    } 1s infinite`,
                }}
              >
                {WatchingNow} Now watching!
              </h2>
            </CardActionArea>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

              {canExport && (
                <div>
                  <input type="file" hidden ref={InputFile} onChange={(e) => {
                    // setFileName(InputFile.current.files[0].name)
                    // setCopyData("");
                    // setIsCopied(false);
                    // setFile(e.target.files[0]);
                    if (e?.target?.files?.length >= 1) {
                      readFile(e.target.files[0])
                    }
                    e.target.value = null
                  }} />

                  {/* <Input
                    ref={InputFile}
                    type={"file"}
                    style={{
                      width: "90%",
                      height: 50,
                      backgroundColor: "#e6e6e6",
                      padding: 10,
                      borderRadius: 10,
                      display: "none"
                    }}
                    disableUnderline={true}
                    onChange={(e) => {
                      // setCopyData("");
                      // setIsCopied(false);
                      // setFile(e.target.files[0]);
                    }}
                  /> */}

                  <CardActionArea
                    onClick={() => {
                      InputFile.current.click();
                    }}
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10
                    }}
                  >
                    {/* <Upload style={{ width: 30, height: 30, color: "primary" }} /> */}
                    <CloudUpload
                      style={{ width: 30, height: 30, color: "primary" }}
                    />
                  </CardActionArea>
                </div>
              )}

              {canAdd && (
                <CardActionArea
                  onClick={() => {
                    setPopup(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10
                  }}
                >
                  <Add style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}
            </div>
          </div>
          <Input
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
            }}
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={"Search"}
          />

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <SubCategoriesTable
                canDelete={canDelete}
                canEdit={canEdit}
                canExport={canExport}
                Search={Search}
                setWatchingNow={setWatchingNow}
                onEdit={(row) => {
                  setSubCategoryToEdit(row);
                  setEditPopup(true);
                }}
                RefreshComp={RefreshComp}
                Refresh={() => {
                  setRefreshComp(!RefreshComp);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategories;
