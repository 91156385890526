//Create Navigation for the react router
import { useEffect, useState } from "react";

import { CardActionArea, Typography, Card, Input, Button } from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetCurrentPage, GetToken, SaveToken } from "../Assets/Storage";
import { ValidateToken } from "../Assets/HttpBank";

import signInBackground from "../Assets/signin.jpg";

const Home = () => {
  const navigate = useNavigate();
  const [Username, setUsername] = useState([]);
  const [Password, setPassword] = useState([]);
  const SignIn = async () => {
    const Response = await fetch(`${Host}/Admin/SignIn`, {
      method: "POST",
      body: JSON.stringify({
        Username: Username,
        Password: Password,
      }),
      headers: {
        "content-type": "application/json",
      },
    });

    if (Response.status === 404) return alert("Invalid Credentials");

    const Data = await Response.json();
    return Data;
  };

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();
      if (Valid) return navigate("/");
      SaveToken("");
    })();
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <img
        src={signInBackground}
        style={{
          position: "absolute",
          width: window.screen.width,
          maxHeight: "100%",
          objectFit: "cover",
          filter: "blur(20px)",
          zIndex: 0,
        }}
        width={"100%"}
      />

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          flexWrap: "wrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
        }}
      >
        <Card
          style={{
            boxShadow: "0px 0px 50px -4px rgba(0,0,0,0.21)",
            width: 500,
            height: "auto",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: 20,
            paddingBottom: 50,
            flexDirection: "column",
            zIndex: 1,
            borderRadius: 0,
          }}
        >
          <h3>Sign In</h3>
          <div
            style={{
              width: "80%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: 10,
              flexDirection: "column",
              gap: 4,
            }}
          >
            {/* <h5>Username</h5> */}

            <Input
              value={Username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              type="username"
              style={{
                width: "100%",
                height: 60,
                padding: 10,
                // backgroundColor: "lightgrey",
              }}
              placeholder="Username"
            />

            {/* <h5>Password</h5> */}

            <Input
              value={Password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              style={{
                width: "100%",
                height: 60,
                padding: 10,
                // backgroundColor: "lightgrey",
              }}
              placeholder="Password"
            />
          </div>

          <Button
            onClick={async () => {
              const { Token, isAdmin } = await SignIn();
              SaveToken(Token);
              const CurrentPage = GetCurrentPage() ? GetCurrentPage() : "";
              if (CurrentPage?.length > 0)
                return window.location.replace(`${CurrentPage}`);

              window.location.replace(`/`);
            }}
            style={{
              width: "80%",
              height: 60,
              backgroundColor: "black",
              color: "white",
              marginTop: 20,
            }}
          >
            Sign In
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default Home;
