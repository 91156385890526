//New react functional component
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  DeleteSerial,
  GetSerialsAndMacsAndCount,
  GetSerialsAndMacsAndCountSearch,
  GetSerialsByReceiverType,
  GetSettings,
} from "../Assets/HttpBank";

import { Delete, Edit } from "@mui/icons-material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ScrollDialog from "./Confirm";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

const Row = (props) => {
  const { row, onDelete, onEdit, canDelete, canEdit } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Serial}
        </TableCell>
        <TableCell align="left">
          {row?.Period}
          {" ~ "}
          {row?.Points > 0 ? (
            <text style={{ color: "green" }}>Paid</text>
          ) : (
            <text style={{ color: "red" }}>Demo</text>
          )}
        </TableCell>
        <TableCell align="left">{row?.ReceiverType}</TableCell>
        <TableCell align="left">{row?.Code}</TableCell>
        <TableCell align="left">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {canEdit && (
              <CardActionArea
                onClick={() => {
                  onEdit?.(row._id);
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Edit style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}

            {canDelete && (
              <CardActionArea
                onClick={() => {
                  onDelete?.();
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Delete style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Extra
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Created At</TableCell>
                    {/* <TableCell>Customer</TableCell> */}
                    {/* <TableCell align="right">Amount</TableCell> */}
                    {/* <TableCell align="right">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {row?.history?.map?.((historyRow) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {new Date(row?.CreatedAt).toDateString()}
                    </TableCell>
                    {/* <TableCell>{historyRow?.customerId}</TableCell> */}
                    {/* <TableCell align="right">{historyRow?.amount}</TableCell> */}
                    {/* <TableCell align="right"> */}
                    {/* {Math.round(historyRow?.amount * row.price * 100) / 100} */}
                    {/* </TableCell> */}
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

export const SerialsTable = (props) => {
  const {
    RefreshCompOut,
    onEdit,
    Search,
    canDelete,
    canEdit,
    ReceiverTypes,
    selectedRecType,
    onisFilteration,
  } = props;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [Serials, setSerials] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshComp, setRefreshComp] = useState(false);

  const CallGet = async () => {
    if (NumberOfRows >= 1) {
      const Data = await GetSerialsAndMacsAndCount(Skip, NumberOfRows);
      const { Count, Serials, Valid } = Data ? Data : {};
      if (!Valid) return;
      setSerials(Serials);
      setCount(Count);
    }
  };

  const CallGetSearch = async () => {
    if (NumberOfRows >= 1) {
      const ReceiverType = ReceiverTypes[selectedRecType];
      let recType = undefined;
      if (ReceiverType != "" && ReceiverType != undefined) {
        recType = ReceiverType;
      }

      const Data = await GetSerialsAndMacsAndCountSearch(
        Skip,
        NumberOfRows,
        Search,
        recType
      );

      if (!Data) {
        alert("Nothing found.");
        // CallGet();
        return;
      }

      const { Count, Serials, Valid } = Data ? Data : {};
      if (!Valid) return;

      setSerials(Serials);
      setCount(Count);
    }
  };

  const [isFilteration, setIsFilteration] = useState(false);

  useEffect(() => {
    onisFilteration?.(isFilteration);
  }, [isFilteration]);

  const CallGetFilteration = async () => {
    const ReceiverType = ReceiverTypes[selectedRecType];
    if (ReceiverType != "" && ReceiverType != undefined) {
      setIsFilteration(true);

      CallGetSearch();
      // const {
      //   Serials = [],
      //   Count,
      //   Valid,
      // } = await GetSerialsByReceiverType(
      //   ReceiverType,
      //   Skip ? Skip : 0,
      //   NumberOfRows ? NumberOfRows : 5
      // );
      // if (Valid) {
      // setIsFilteration(true);
      //   setSerials(Serials);
      //   setCount(Count);
      // }
    } else {
      setSkip(0);
      setPage(0);
      setIsFilteration(false);
      CallGet();
    }
  };

  // useEffect( () =>
  // {
  //   if (Search?.length >= 1) {
  //     CallGetSearch();
  //   } else {
  //     CallGet();
  //   }
  // }, [Skip, NumberOfRows, RefreshComp, RefreshCompOut]);

  const [SearchIsChanged, setSearchIsChanged] = useState("");
  useEffect(() => {
    setSearchIsChanged(Search);
  }, []);

  useEffect(() => {
    const ReceiverType = ReceiverTypes[selectedRecType];
    if (ReceiverType != "" && ReceiverType != undefined) {
      setIsFilteration(true);
    } else {
      setIsFilteration(false);
      if (Search?.length >= 1) {
        if (SearchIsChanged != Search) {
          setSearchIsChanged(Search);
        }
        CallGetSearch();
      } else {
        console.log(Skip, NumberOfRows, Search);
        CallGet();
      }
    }
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompOut, Search]);

  const [Open, setOpen] = useState(false);
  const [IdToAction, setIdToAction] = useState("");

  useEffect(() => {
    const ReceiverType = ReceiverTypes[selectedRecType];

    if (ReceiverType != "" && ReceiverType != undefined) {
      // alert("Filteration is on.");

      CallGetFilteration();
    } else {
      if (Search?.length <= 0) {
        CallGet();
      }
    }
  }, [selectedRecType, Skip, Search, RefreshCompOut, RefreshComp]);

  return (
    <TableContainer component={Paper}>
      {Open && (
        <ScrollDialog
          Confirm={async () => {
            const DeleteCode = await DeleteSerial({
              SerialID: IdToAction,
            });

            const RandomInt = getRandomInt(100000);
            window.location.href = `#${RandomInt}`;

            setRefreshComp(!RefreshComp);
            setOpen(false);
          }}
          Cancel={() => {
            setOpen(false);
          }}
          Title={"Are you sure?"}
          Message={
            "You are removing a Serial Number that may have been used by an individuals. Do you want to continue?"
          }
        />
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Value</TableCell>
            <TableCell align="left">Period In Days</TableCell>
            <TableCell align="left">ReceiverType</TableCell>
            <TableCell align="left">Code</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: Serials.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {Serials.map((row, index) => (
            <Row
              canDelete={canDelete}
              canEdit={canEdit}
              onDelete={async () => {
                setIdToAction(row._id);
                setOpen(true);
              }}
              onEdit={() => {
                onEdit?.(row);
              }}
              key={index}
              row={row}
            />
          ))}
        </TableBody>

        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",

            padding: 10,
          }}
        >
          <TablePagination
            component="div"
            count={Count}
            page={Count <= 0 ? 0 : Page}
            onPageChange={(c, page) => {
              setPage(page);
              setSkip(page * NumberOfRows);

              //   alert(page);
            }}
            rowsPerPage={NumberOfRows}
            rowsPerPageOptions={[6]}
          />
        </div>
      </Table>
    </TableContainer>
  );
};

export default SerialsTable;
