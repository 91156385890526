//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  Snackbar,
  Alert,
  Checkbox,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import {
  GetSomeObject,
  GetToken,
  SaveCurrentPage,
  SaveSomeObject,
  SaveToken,
} from "../Assets/Storage";
import {
  ValidateToken,
  GetPoints,
  GetSettings,
  EditSettings,
} from "../Assets/HttpBank";
import EditCategory from "../Components/EditCategory";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import { Add, VisibilityOff, Visibility, Upload } from "@mui/icons-material";

import AddCategory from "../Components/AddCategory";

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
const Settings = () => {
  const navigate = useNavigate();

  const [Settings, setSettings] = useState({});

  const [AppName, setAppName] = useState("");
  const [BlockedCountry, setBlockedCountry] = useState("");
  const [BlockedClient, setBlockedClient] = useState("");
  const [NotFound, setNotFound] = useState("");
  const [UnAuhtorized, setUnAuhtorized] = useState("");

  const [NumberOfRows, setNumberOfRows] = useState(0);
  const [CodeLength, setCodeLength] = useState(0);
  const [IsSeriesSortingASC, setIsSeriesSortingASC] = useState(false);

  const [AlertOnPoints, setAlertOnPoints] = useState(0);
  const [PointsDeductionType, setPointsDeductionType] = useState(false);

  const [MessageForExpiration, setMessageForExpiration] = useState("");

  const [Logo, setLogo] = useState("");
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const Data = JSON.parse(Cached);
      const { Settings, Valid } = Data ? Data : {};
      setSettings(Settings ? Settings : {});
      setAppName(Settings ? Settings.AppName : "");
      setBlockedCountry(Settings ? Settings.BlockedCountry : "");
      setBlockedClient(Settings ? Settings.BlockedClient : "");
      setNotFound(Settings ? Settings.NotFound : "");
      setUnAuhtorized(Settings ? Settings.UnAuhtorized : "");

      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);

      setCodeLength(Settings ? Settings.CodeLength : 13);

      setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);

      setIsSeriesSortingASC(Settings ? Settings.IsSeriesSortingASC : false);
      setLogo(Settings ? Settings.Logo : "");

      setPointsDeductionType(Settings ? Settings.PointsDeductionType : false);

      setMessageForExpiration(Settings ? Settings.MessageForExpiration : "");
    }
    (async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      if (Valid) SaveSomeObject("GetSettings", Data ? Data : {});
      setSettings(Settings ? Settings : {});
      setAppName(Settings ? Settings.AppName : "");
      setBlockedCountry(Settings ? Settings.BlockedCountry : "");
      setBlockedClient(Settings ? Settings.BlockedClient : "");
      setNotFound(Settings ? Settings.NotFound : "");
      setUnAuhtorized(Settings ? Settings.UnAuhtorized : "");

      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);

      setCodeLength(Settings ? Settings.CodeLength : 13);

      setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);

      setIsSeriesSortingASC(Settings ? Settings.IsSeriesSortingASC : false);
      setLogo(Settings ? Settings.Logo : "");

      setPointsDeductionType(Settings ? Settings.PointsDeductionType : false);

      setMessageForExpiration(Settings ? Settings.MessageForExpiration : "");
    })();
  }, []);


  useEffect(() => {
    console.log(PointsDeductionType);
  }, [PointsDeductionType])

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Settings");
    })();
  }, []);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const InputFile = createRef();

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        ref={InputFile}
        type="file"
        style={{ display: "none" }}
        onChange={async (e) => {
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            //Set the value of the input
            // console.log(reader.result);
            setLogo(reader.result);
          };
        }}
      />
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type ? Message?.Type : "info"}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Settings</h2>
          </div>

          <div
            style={{
              width: "100%",
              height: "90%",
              position: "absolute",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 20,
                  // justifyContent: "center",
                }}
              >
                <CardActionArea
                  onClick={() => {
                    InputFile.current.click();
                  }}
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Upload
                    style={{ width: 25, height: 25, position: "absolute" }}
                  />
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 100,
                      backgroundColor: `rgb(0,0,0,0.2)`,
                      position: "absolute",
                    }}
                  />
                  <img
                    style={{ width: 100, height: 100, borderRadius: 100 }}
                    src={Logo}
                  />
                </CardActionArea>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={AppName}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"APP Name"}
                  onChange={(e) => {
                    setAppName(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>APP Name</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={BlockedCountry}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"Blocked Country Message"}
                  onChange={(e) => {
                    setBlockedCountry(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>Blocked Country Message</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={BlockedClient}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"Blocked Client Message"}
                  onChange={(e) => {
                    setBlockedClient(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>Blocked Client Message</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={NotFound}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"Not Found Message"}
                  onChange={(e) => {
                    setNotFound(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>Not Found Message</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={UnAuhtorized}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"UnAuhtorized Message"}
                  onChange={(e) => {
                    setUnAuhtorized(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>UnAuhtorized Message</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Input
                  type={"text"}
                  value={MessageForExpiration}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"Message For Expiration"}
                  onChange={(e) => {
                    setMessageForExpiration(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>Message For Expiration</h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  width: "60%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <Input
                    type={"number"}
                    value={NumberOfRows}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#e6e6e6",
                      padding: 10,
                      borderRadius: 10,
                    }}
                    disableUnderline={true}
                    placeholder={"Max Number Of Rows"}
                    onChange={(e) => {
                      setNumberOfRows(Number(e.target.value));
                    }}
                  />
                  <h4 style={{ fontSize: 10 }}>Max Number Of Rows</h4>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "45%",
                  }}
                >
                  <Input
                    type={"number"}
                    value={CodeLength}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#e6e6e6",
                      padding: 10,
                      borderRadius: 10,
                    }}
                    disableUnderline={true}
                    placeholder={"Code Length"}
                    onChange={(e) => {
                      setCodeLength(Number(e.target.value));
                    }}
                  />
                  <h4 style={{ fontSize: 10 }}>Code Length</h4>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <Input
                  type={"number"}
                  value={AlertOnPoints}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"The number of points to alert when reached."}
                  onChange={(e) => {
                    setAlertOnPoints(Number(e.target.value));
                  }}
                />
                <h4 style={{ fontSize: 10 }}>
                  The number of points to alert when reached.
                </h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <Checkbox
                  // value={PointsDeductionType}
                  checked={PointsDeductionType}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  placeholder={
                    "Points Deduction Method, If checked, it will be deducted on activated clients."
                  }
                  onChange={(e) => {
                    setPointsDeductionType(e.target.checked);
                  }}
                />
                <h4 style={{ fontSize: 15 }}>
                  Points Deduction Method, If checked, it will be deducted on
                  activated clients.
                </h4>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <Input
                  type={"text"}
                  value={IsSeriesSortingASC}
                  style={{
                    width: "60%",
                    height: 50,
                    backgroundColor: "#e6e6e6",
                    padding: 10,
                    borderRadius: 10,
                  }}
                  disableUnderline={true}
                  placeholder={"Is Series upside down? true/false"}
                  onChange={(e) => {
                    setIsSeriesSortingASC(e.target.value);
                  }}
                />
                <h4 style={{ fontSize: 10 }}>Is Series upside down?</h4>
              </div>

              <CardActionArea
                onClick={async () => {
                  setMessage({ Message: "Saving...", Type: "info" });
                  setOpen(true);
                  const SettingSave = await EditSettings({
                    Settings: {
                      AppName,
                      BlockedCountry,
                      BlockedClient,
                      NotFound,
                      UnAuhtorized,
                      NumberOfRows,
                      CodeLength,
                      Logo,
                      AlertOnPoints,
                      IsSeriesSortingASC:
                        IsSeriesSortingASC === "true" ||
                        IsSeriesSortingASC === "1" ||
                        IsSeriesSortingASC === true,
                      PointsDeductionType,
                      MessageForExpiration,
                    },
                  });
                  const RandomInt = getRandomInt(50000);
                  window.location.href = `#${RandomInt}`;
                  setMessage({ Message: "Saved!", Type: "success" });
                  setOpen(true);
                }}
                style={{
                  width: "60%",
                  height: 50,
                  backgroundColor: "#e6e6e6",
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <h4>Save</h4>
              </CardActionArea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
