//Create Navigation for the react router
import { useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import {
  DeleteAllClients,
  DownloadExpiredClients,
  GetPrivileges,
  ValidateToken,
} from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import { Add } from "@mui/icons-material";
import AddCode from "../Components/AddCode";
import ClientsTable from "../Components/ClientsTable";
import EditClient from "../Components/EditClient";
import ScrollDialog from "../Components/Confirm";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    border: `2px solid red`,
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    color: "white",
    fontWeight: "bold",
  },
});

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const Clients = (prop) => {
  const classes = useStyles();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Clients");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [ClientToEdit, setClientToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [OpenDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();
      setIsAdmin(isAdmin);
      console.log(Privileges);
      if (isAdmin) {
        setCanEdit(true);
        setCanDelete(true);
      } else {
        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Activated Clients"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Activated Clients"
        );

        setCanDelete(GetDelete[0]?.permitted);
      }
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  //Get Params from the url

  const [Search, setSearch] = useState();

  useEffect(() => {
    if (window?.location?.search?.length >= 1) {
      // setTimeout(() => {
      setSearch(window.location?.search?.replace("?search=", ""));
      // }, 700);
    }
  }, []);

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {OpenDialog && (
        <ScrollDialog
          Confirm={async () => {
            setOpenDialog(false);
            const DeleteAll = await DeleteAllClients({
              Query: Search,
            });

            const RandomInt = getRandomInt(50000);
            window.location.href = `#${RandomInt}`;

            setRefreshComp(!RefreshComp);
            setSearch("");
          }}
          Cancel={() => {
            setOpenDialog(false);
          }}
          Title={"Are you sure?"}
          Message={`You might be removing a huge number of Clients, Are you sure? ${
            Search ? `Your removing, User: ${Search}` : ""
          }`}
        />
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditClient
            ClientToEdit={ClientToEdit}
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          onClick={() => {
            setEditPopup(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Activated Clients</h2>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              style={{
                width: "60%",
                height: 60,
                backgroundColor: "lightgrey",
                marginTop: 20,
              }}
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder={"Search"}
            />

            {canDelete && isAdmin && (
              <CardActionArea
                className="FocusedItem"
                onClick={() => {
                  setOpenDialog(true);
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h4>Delete All</h4>
              </CardActionArea>
            )}

            {isAdmin && (
              <CardActionArea
                className={classes.root}
                onClick={async () => {
                  await DownloadExpiredClients("excel");
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // borderWidth: 100,
                  // borderColor: "red",
                }}
              >
                <h4>DownLoad expired clients</h4>
              </CardActionArea>
            )}
          </div>

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <ClientsTable
                canDelete={canDelete}
                canEdit={canEdit}
                onEdit={(row) => {
                  setClientToEdit(row);
                  setEditPopup(true);
                }}
                onDelete={(DeleteClientID) => {
                  const RandomInt = getRandomInt(50000);
                  window.location.href = `#${RandomInt}`;

                  setRefreshComp(!RefreshComp);
                }}
                Search={Search}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
