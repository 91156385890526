//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  GetSubCategoriesCount,
  GetSubCategoriesAndCountSearch,
  GetSettings,
  DeleteSubCategory,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import socketio from "socket.io-client";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import SubCategoryItem from "./SubCategoryItem";
import ScrollDialog from "./Confirm";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const SubCategoriesTable = (prop) => {
  const { RefreshComp, onEdit, Search, canDelete, canEdit, canExport, Refresh, setWatchingNow } = prop;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [SubCategory, setSubCategory] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const RefreshSubCategories = async () => {
    if (NumberOfRows >= 1) {
      const Data = await GetSubCategoriesCount({ Skip, Limit: NumberOfRows });
      const { Count, SubCategories, Valid } = Data ? Data : {};
      if (!Valid) return;
      setSubCategory(SubCategories);
      setCount(Count);
    }
  };

  useEffect(() => {
    if (Search?.length <= 0) {
      RefreshSubCategories();
    }
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetSubCategoriesAndCountSearch(
          Skip,
          NumberOfRows,
          Search
        );
        if (!Data) {
          return RefreshSubCategories();
        }

        const { Count, SubCategories, Valid } = Data ? Data : {};
        if (!Valid) return;
        setSubCategory(SubCategories);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  const [IdToAction, setIdToAction] = useState("");

  const [confirmDelete, setconfirmDelete] = useState(false);


  const [socket, setSocket] = useState(undefined)
  const setupSocket = async () => {
    const socket = socketio(
      `http://realtime.${Host.replace("http://", "").replace("https://", "")}`
    ).connect();
    setSocket(socket)

    // socket.on("watchingNowSubCategory", (data) => {
    //   const Array = [...SubCategory]
    //   const ItemFound = Array.filter((Item) => data === Item?._id)
    //   console.log(data, ItemFound, SubCategory);
    // });

    socket.on("watchingNow", (data) => {
      setWatchingNow(data > 0 ? data : 0);
    });

    socket.emit("getWatchingNow");


    return socket;
  };

  useEffect(() => {
    const socketPromise = setupSocket();

    return async () => {
      const socket = await socketPromise;

      socket.off();
      socket.offAny();
      socket.close();
    };
  }, []);


  return (
    <TableContainer component={Paper} style={{ maxHeight: "100%" }}>
      {confirmDelete && (
        <ScrollDialog
          Confirm={async () => {
            setconfirmDelete(false);
            const CategoryDeleteAction = await DeleteSubCategory({
              SubCategoryID: IdToAction,
            });

            if (CategoryDeleteAction.Code === 403) {
              return alert("Can't delete this Sub Category, for some reason.");
            }
            Refresh?.();
          }}
          Cancel={() => {
            setconfirmDelete(false);
          }}
          Title={"Are you sure?"}
          Message={
            "You are removing a Sub Category that may have been used by a many of factors. Do you want to continue?"
          }
        />
      )}

      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Live Views</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: SubCategory.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {SubCategory.map((row, index) => {
            return (
              <SubCategoryItem
                socket={socket}
                canDelete={canDelete}
                canEdit={canEdit}
                canExport={canExport}
                key={index}
                row={row}
                onDelete={() => {
                  setconfirmDelete(true);
                  setIdToAction(row._id);
                }}
                RefreshCompInside={() => {
                  setRefreshCompInside(!RefreshCompInside);
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>

      <div
        style={{
          width: "100%",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",

          padding: 10,
        }}
      >
        <TablePagination
          component="div"
          count={Count}
          page={Page}
          onPageChange={(c, page) => {
            setPage(page);
            setSkip(page * NumberOfRows);
            //   alert(page);
          }}
          rowsPerPage={NumberOfRows}
          rowsPerPageOptions={[6]}
        />
      </div>
    </TableContainer>
  );
};

export default SubCategoriesTable;
