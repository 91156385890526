//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import {
  CreateCodes,
  GetPeriods,
  GetSettings,
  GetSubCategoriesCount,
} from "../Assets/HttpBank";
import CheckBox from "./Checkbox";

const AddCode = (prop) => {
  const { canManual, canGenerate } = prop;

  const navigate = useNavigate();
  const [Value, setValue] = useState("");
  const [Period, setPeriod] = useState(0);
  const [Points, setPoints] = useState(0);
  const [ClientType, setClientType] = useState("");
  const [Company, setCompany] = useState("");
  const [Limit, setLimit] = useState(0);

  const [Periods, setPeriods] = useState([]);

  const [isGenerate, setIsGenerate] = useState(canManual ? false : true);

  const [CodeLength, setCodeLength] = useState(0);

  const [SubCategories, setSubCategories] = useState([]);

  const [IsAllSubCategories, setIsAllSubCategories] = useState(false);
  useEffect(() => {
    (async () => {
      const Data = await GetSubCategoriesCount({});
      const { Count, SubCategories, Valid } = Data ? Data : {};
      if (Valid) {
        const SubCategoriesData = [];
        for (let index = 0; index < SubCategories.length; index++) {
          const element = SubCategories[index];
          if (element) {
            element.permitted = false;

            SubCategoriesData.push(element);
          }
        }
        setSubCategories(SubCategoriesData);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      setCodeLength(Settings ? Settings.CodeLength : 13);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Periods = await GetPeriods();
      setPeriods(Periods?.Periods ? Periods?.Periods : []);
      if (Periods?.Periods[0]) {
        setPeriod(Periods?.Periods[0].Period);
        setPoints(Periods?.Periods[0].Points);
      }
    })();
  }, []);

  const Create = async () => {
    prop?.Message?.({ Message: "Creating Codes...", Type: "info" });
    const CreatedCodes = await CreateCodes({
      Value,
      Limit: isGenerate ? 1 : Limit,
      Company,
      ClientType,
      isGenerate,
      Period,
      SubCategories,
    });

    if (!CreatedCodes?.Valid) {
      prop?.Message?.({ Message: "Couldn't create the codes.", Type: "error" });
      return;
    }

    prop?.Message?.({
      Message: `${CreatedCodes?.Generated} Codes created!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: canManual ? "80%" : "30%",
        height: "90%",
        maxHeight: "90%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Activation Code.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div
        style={{
          width: canManual ? "40%" : "95%",
          height: 1,
          backgroundColor: "lightgrey",
        }}
      />

      <div
        style={{
          width: canManual ? "41%" : "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={isGenerate ? "number" : "text"}
          value={Value}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={isGenerate ? "Generate Number" : "Manual Code"}
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, CodeLength);
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {canGenerate && (
          <Checkbox
            disabled={!canManual}
            style={{ width: "10%" }}
            label="Label"
            checked={isGenerate}
            onChange={(e) => {
              setIsGenerate(e.target.checked);
            }}
          />
        )}
      </div>

      <FormControl
        style={{
          width: canManual ? "40%" : "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Period In Days?</InputLabel>
        <Select
          onChange={(e) => {
            setPeriod(e.target.value);
            setPoints(
              Periods.filter((x) => x.Period == e.target.value)[0]?.Points
            );
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Period}
          label="Age"
          // onChange={handleChange}
        >
          {Periods.map((Period) => {
            return (
              <MenuItem value={Period?.Period}>
                {Period?.Period} Days - {Period?.Points <= 0 ? "Demo" : "Paid"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div
        style={{
          width: canManual ? "40%" : "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          style={{
            width: "48%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Company"}
          value={Company}
          onChange={(e) => {
            setCompany(e.target.value);
          }}
        />
        <Input
          style={{
            width: "48%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"ClientType"}
          value={ClientType}
          onChange={(e) => {
            setClientType(e.target.value);
          }}
        />
      </div>

      <Input
        type="number"
        style={{
          width: canManual ? "40%" : "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginLeft: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        value={isGenerate ? 1 : Limit}
        disabled={isGenerate}
        placeholder={"Limit"}
        onChange={(e) => {
          setLimit(e.target.value);
        }}
      />

      <h4 style={{ marginTop: 50, color: "orange", fontWeight: "bolder" }}>
        Please keep in mind, That this will cost{" "}
        {isGenerate ? Points * Value : Points} Points
      </h4>

      <div
        style={{
          width: canManual ? "40%" : "95%",
          height: 1,
          backgroundColor: "lightgrey",
        }}
      />

      <Button
        style={{
          width: canManual ? "40%" : "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Create();
        }}
      >
        <h5>Create</h5>
      </Button>

      {canManual && (
        <div
          style={{
            width: "57%",
            display: "flex",
            flexDirection: "column",
            maxHeight: "90%",
            height: "90%",
            // overflow: "scroll",
            position: "absolute",
            top: "10%",
            right: "-20px",
            bottom: "-20px",
            // right: "-20px",
            overflow: "scroll",
            opacity: SubCategories.length <= 0 ? 0 : 1,
            transition: "all .2s",
          }}
        >
          <div>
            <h3>Allowed - Sub Categories</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginRight: 15,
              }}
            >
              <CheckBox
                Name={"All"}
                style={{ padding: 10 }}
                isChecked={IsAllSubCategories}
                // isDisabled={isGenerate}
                onChecked={(Checked, Name) => {
                  setIsAllSubCategories(Checked);

                  const NewArray = [];
                  for (let index = 0; index < SubCategories.length; index++) {
                    const element = SubCategories[index];
                    if (element) {
                      element.permitted = Checked;
                      NewArray.push(element);
                    }
                  }
                  // console.log([...NewArray]);
                  setSubCategories([...NewArray]);
                }}
              />

              {SubCategories?.map?.((item, index) => {
                return (
                  <CheckBox
                    useStyle={true}
                    Name={item?.Name}
                    // isDisabled={isGenerate}
                    style={{ padding: 10 }}
                    isChecked={item?.permitted}
                    onChecked={(Checked, Name) => {
                      item.permitted = Checked;

                      let CheckNum = SubCategories.length;
                      for (
                        let index = 0;
                        index < SubCategories.length;
                        index++
                      ) {
                        const element = SubCategories[index];
                        if (!element?.permitted) {
                          CheckNum--;
                        }
                      }

                      console.log(CheckNum);
                      if (CheckNum >= SubCategories.length) {
                        setIsAllSubCategories(true);
                      } else {
                        setIsAllSubCategories(false);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default AddCode;
