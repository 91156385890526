import { useEffect, useMemo, useState } from "react";

//Get Params from URL

import { useNavigate } from "react-router-dom";

import {
  CardActionArea,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { GetPoints, GetPrivileges, GetSettings } from "../Assets/HttpBank";
import {
  GetSomeObject,
  GetToken,
  SaveCurrentPage,
  SaveSomeObject,
  SaveToken,
} from "../Assets/Storage";
import NavItem from "./NavItem";

const NavigationBar = (props = {}) => {
  const { PointsVisible = false } = props;
  const [Points, setPoints] = useState(0);

  useEffect(() => {
    const Cached = GetSomeObject("GetPoints");
    if (Cached) {
      const Data = JSON.parse(Cached);
      const { Balance, StartedBalance, isAdmin, Valid } = Data ? Data : {};
      setPoints(isAdmin && !PointsVisible ? "Infinite" : Valid ? Balance : 0);
    }
    (async () => {
      const Data = await GetPoints();
      const { Balance, StartedBalance, isAdmin, Valid } = Data ? Data : {};
      SaveSomeObject("GetPoints", Data ? Data : {});
      setPoints(isAdmin && !PointsVisible ? "Infinite" : Valid ? Balance : 0);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { Balance, StartedBalance, isAdmin, Valid } = await GetPoints();
      setPoints(isAdmin && !PointsVisible ? "Infinite" : Valid ? Balance : 0);
    })();
  }, [PointsVisible]);

  const navigate = useNavigate();

  const [MenuItems, setMenuItems] = useState([]);

  // const SetMenuItems = () => {
  //   const isAdmin = GetRank();
  // };

  const [Settings, setSettings] = useState({});

  const [AppName, setAppName] = useState("");
  const [NumberOfRows, setNumberOfRows] = useState(0);
  const [Logo, setLogo] = useState(undefined);

  const [AlertOnPoints, setAlertOnPoints] = useState(0);
  const GetSettingsInformation = async () => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const Data = JSON.parse(Cached);
      const { Settings, Valid } = Data ? Data : {};
      setSettings(Settings ? Settings : {});
      setAppName(Settings ? Settings.AppName : "");
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
      setLogo(Settings ? Settings.Logo : "");
      setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);
    }

    const Data = await GetSettings();
    const { Settings, Valid } = Data ? Data : {};
    SaveSomeObject("GetSettings", Data ? Data : {});

    setSettings(Settings ? Settings : {});
    setAppName(Settings ? Settings.AppName : "");
    setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    setLogo(Settings ? Settings.Logo : "");
    setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);
  };

  useEffect(() => {
    GetSettingsInformation();
  }, []);

  const RefreshMenuItems = (Privileges, isAdmin, Valid) => {
    let Navigations = [];
    for (let index = 0; index < Privileges?.Navigation?.length; index++) {
      const { Name, Path, permitted } = Privileges?.Navigation[index];
      if (permitted || isAdmin) {
        //Devices Information
        Navigations.push({ Name, Path: `/${Path}` });
      }
    }
    const DevicesInformation = [];
    const CategoryManagement = [];
    const UserManagement = [];
    const NewNavArray = [];
    const AdminManagement = [];
    const SeriesManagement = [];
    const MoviesAndLivesManagement = [];

    for (const element of Navigations) {
      const { Name, Path } = element;
      if (
        Name === "Codes" ||
        Name === "Serials & Mac Addresses" ||
        Name === "Activated Clients"
      ) {
        DevicesInformation.push({ Name, Path });
      } else if (Name === "Categories" || Name === "Sub Categories") {
        //Category Management
        CategoryManagement.push({ Name, Path });
      } else if (Name === "Users") {
        //User Management
        UserManagement.push({ Name, Path });
      } else if (
        Name === "Periods" ||
        Name === "Countries" ||
        Name === "Settings" ||
        Name === "Servers"
      ) {
        //Admin Management
        AdminManagement.push({ Name, Path });
      } else if (Name === "Series" || Name === "Series Episodes") {
        //Series Management
        SeriesManagement.push({ Name, Path });
      } else if (Name === "Lives" || Name === "Movies") {
        MoviesAndLivesManagement.push({ Name, Path });
      } else {
        NewNavArray.push({ Name, Path });
      }
    }

    const NewNav = [];
    if (CategoryManagement.length >= 1) {
      NewNav.push({
        Name: "Category Management",
        Tabs: [...CategoryManagement],
      });
    }
    if (DevicesInformation.length >= 1) {
      NewNav.push({
        Name: "Devices Information",
        Tabs: [...DevicesInformation],
      });
    }
    if (SeriesManagement.length >= 1) {
      NewNav.push({ Name: "Series Management", Tabs: [...SeriesManagement] });
    }
    if (MoviesAndLivesManagement.length >= 1) {
      NewNav.push({
        Name: "Movies & Lives Management",
        Tabs: [...MoviesAndLivesManagement],
      });
    }
    NewNav.push(...NewNavArray);
    if (UserManagement.length >= 1) {
      NewNav.push({ Name: "User Management", Tabs: [...UserManagement] });
    }
    if (AdminManagement.length >= 1) {
      NewNav.push({ Name: "Admin Management", Tabs: [...AdminManagement] });
    }

    setMenuItems([...NewNav]);
  };

  const RefreshPoints = async () => {
    const { Balance, StartedBalance, isAdmin, Valid } = await GetPoints();
    setPoints(isAdmin && !PointsVisible ? "Infinite" : Valid ? Balance : 0);
  };
  useEffect(() => {
    const Cached = GetSomeObject("GetPrivileges");
    if (Cached) {
      const Data = JSON.parse(Cached);
      const { Privileges, isAdmin, Valid } = Data;
      RefreshMenuItems(Privileges, isAdmin, Valid);
    }
    (async () => {
      const Data = await GetPrivileges();
      const { Privileges, isAdmin, Valid } = Data ? Data : {};
      if (!Valid) return alert("Something went wrong.");
      SaveSomeObject("GetPrivileges", Data ? Data : {});
      RefreshMenuItems(Privileges, isAdmin, Valid);
    })();
  }, []);

  useEffect(() => {
    const Listener = window.addEventListener("hashchange", () => {
      RefreshPoints();
      GetSettingsInformation();
    });
    return () => {
      window.removeEventListener("hashchange", Listener);
    };
  }, []);

  return (
    <div
      style={{
        width: "20%",
        minWidth: "20%",
        height: "100%",
        background: "#4267B2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // overflow: "scroll",
      }}
    >
      {GetToken()?.length > 0 && (
        <div
          // className="SideBarItem"
          style={{
            backgroundColor: "white",
            width: "100%",
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CardActionArea
            onClick={() => {
              SaveToken("");
              SaveCurrentPage("");
              window.location.replace("/SignIn");
            }}
            style={{
              display: "flex",
              width: "100%",
              height: 60,
              backgroundColor: `rgb(220,220,220,0.5)`,
            }}
          >
            <h4>SignOut</h4>
          </CardActionArea>
        </div>
      )}
      <CardActionArea
        className="SideBarItem"
        onClick={async () => {
          //Create Session
          // const { id: SessionId } = await CreateSession(id, "Series");
          navigate(`/`);
        }}
        style={{
          width: "90%",
          height: 70,
          display: "flex",
          alignItems: "center",
          borderRadius: 10,
          marginTop: 10,
          justifyContent: "flex-start",
          color: "black",
          flexDirection: "row",
          backgroundColor:
            window?.location?.pathname === "/"
              ? `rgb(220,220,220,0.5)`
              : undefined,
          overflow: "hidden",
          opacity: !Points ? 0 : 1,
          transition: "all .6s",
        }}
      >
        {Logo && (
          <img
            src={Logo}
            width={50}
            height={50}
            style={{ borderRadius: 50, marginLeft: 20 }}
          />
        )}

        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
        >
          <Typography>{AppName}</Typography>

          {Logo && (
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 0 }}
            >
              <Typography
                variant="h5"
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  color: Points <= AlertOnPoints ? "orange" : "black",
                }}
              >
                {Points}
              </Typography>

              <Typography
                variant="h5"
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "darkgreen",
                }}
              >
                Points
              </Typography>
            </div>
          )}
        </div>
      </CardActionArea>

      <CardActionArea
        onClick={async () => {
          navigate(`Clients`);
        }}
        style={{
          width: "90%",
          marginLeft: 10,
          height: 40,
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
          marginTop: 10,
          padding: 10,
          backgroundColor:
            window?.location?.pathname === "/Clients"
              ? `orange`
              : undefined,
        }}
        className="SideItem"
      >
        <Typography
          style={{
            color:
              window?.location?.pathname === "/Clients" ? "white" : "white",
            fontSize: 15,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {"Activated Clients"}
        </Typography>
      </CardActionArea>


      <CardActionArea
        onClick={async () => {
          navigate(`Servers`);
        }}
        style={{
          width: "90%",
          marginLeft: 10,
          height: 40,
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
          marginTop: 10,
          padding: 10,
          backgroundColor:
            window?.location?.pathname === "/Servers"
              ? `orange`
              : undefined,
        }}
        className="SideItem"
      >
        <Typography
          style={{
            color:
              window?.location?.pathname === "/Servers" ? "white" : "white",
            fontSize: 15,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {"Load balancing"}
        </Typography>
      </CardActionArea>

      {MenuItems.map((Item, Index) => {
        const { Name, Path, isShowing = false, Tabs = [] } = Item;
        return (
          <NavItem
            key={Index}
            Name={Name}
            Path={Path}
            isShowing={isShowing}
            Tabs={Tabs}
            onClick={(isShowing) => {
              const newArr = [];
              for (let index = 0; index < MenuItems.length; index++) {
                if (Name === MenuItems[index]?.Name) {
                  newArr.push({ ...MenuItems[index], isShowing: isShowing });
                } else {
                  newArr.push({ ...MenuItems[index], isShowing: false });
                }
              }
              setMenuItems([...newArr]);
            }}
          />
        );
      })}
    </div>
  );
};

export default NavigationBar;
