//Save Token to local storage
export const SaveToken = (Token) => {
  localStorage.setItem("Token", Token);
  return Token;
};

export const GetToken = () => {
  return localStorage.getItem("Token");
};

export const SaveCurrentPage = (Path) => {
  localStorage.setItem("CurrentPage", Path);
  return Path;
};

export const GetCurrentPage = () => {
  return localStorage.getItem("CurrentPage");
};

export const SaveSomeObject = (Name, Data) => {
  return localStorage.setItem(Name, JSON.stringify(Data));
};

export const GetSomeObject = (Name) => {
  return localStorage.getItem(Name);
};
