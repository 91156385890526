//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import {
  CreateSerialsMass,
  DeleteAllSerialsByCode,
  GetPrivileges,
  GetReceiverTypes,
  GetSerialsByReceiverType,
  ValidateToken,
} from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import SerialsTable from "../Components/SerialsTable";
import AddSerial from "../Components/AddSerial";
import EditSerial from "../Components/EditSerial";
import ScrollDialog from "../Components/Confirm";
import UploadSerial from "../Components/UploadSerial";
import MassEditSerialsComponent from "../Components/MassEditSerialsComponent";
import { Add, CloudUpload, Edit } from "@mui/icons-material";

const useStyles = makeStyles({
  select: {
    "&:before": {
      borderColor: "darkgreen",
    },
    "&:after": {
      borderColor: "lightblue",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "darkgreen",
    },
  },
  icon: {
    fill: "darkgreen",
  },
  root: {
    color: "darkgreen",
  },
});

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const Serials = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }
      SaveCurrentPage("/SerialsAndMacs");
    })();
  }, []);

  const [RefreshCompOut, setRefreshCompOut] = useState(false);
  //Upload file and read line by line
  const readFile = async (e) => {
    const file = e.target.files[0];
    //file is a file with lines separated by \n read as text
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      const Serials = [];
      const text = reader.result;
      const ArrayText = text?.split("\n");
      for (let index = 0; index < ArrayText.length; index++) {
        const element = ArrayText[index];
        const lines = element.split(",");
        const Serial = {
          Serial: lines[0],
          Code: lines[1],
          Period: lines[2],
          ReceiverType: lines[3],
          CreatedAt: new Date(),
        };
        Serials.push(Serial);
      }

      const { Valid, InsertedSerialsCount, InsertedErrors } =
        await CreateSerialsMass({
          Serials,
        });
      console.log(Valid, InsertedSerialsCount, InsertedErrors);

      if (Valid) {
        alert(
          `Serials Created, ${InsertedSerialsCount} Serials Inserted, ${InsertedErrors} Errors`
        );
      } else {
        alert("Error Creating Serials, Check the file format");
      }
      setRefreshCompOut(!RefreshCompOut);
    };
    return "";
  };

  //Init Input for file, to use in the render
  const InputFile = createRef();

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [SerialtoEdit, setSerialtoEdit] = useState({});
  const [MassSerialEdit, setMassSerialEdit] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpload, setcanUpload] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();
      setIsAdmin(isAdmin);
      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
        setcanUpload(true);
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Serials & Mac Addresses"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Serials & Mac Addresses"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Serials & Mac Addresses"
        );

        setCanDelete(GetDelete[0]?.permitted);

        const GetUpload = Privileges?.Upload?.filter?.(
          (item) => item.Name === "Serials & Mac Addresses"
        );

        setcanUpload(GetUpload[0]?.permitted);
      }
    })();
  }, []);

  const [ReceiverTypes, setReceiverTypes] = useState([]);

  const [selectedRecType, setselectedRecType] = useState("");

  useEffect(() => {
    (async () => {
      const { ReceiverTypes = [], Valid } = await GetReceiverTypes();
      if (Valid) {
        //Make ReceiverTypes an array of unique value
        const UniqueReceiverTypes = ReceiverTypes.filter(
          (item, index) => ReceiverTypes.indexOf(item) === index
        );

        // Remove only White space from ReceiverTypes, or empty strings
        const CleanReceiverTypes = UniqueReceiverTypes.filter(
          (item) => item !== "" && item !== " "
        );

        setReceiverTypes(CleanReceiverTypes);
        console.log(CleanReceiverTypes);
      }
    })();
  }, [, RefreshCompOut, selectedRecType]);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  const [OpenDialog, setOpenDialog] = useState(false);

  const [DeleteAll, setDeleteAll] = useState("No");

  const [OpenUpload, setOpenUpload] = useState(false);

  const [IsSearchable, setIsSearchable] = useState(true);

  const [canMassEdit, setcanMassEdit] = useState(false);
  useEffect(() => {
    if (selectedRecType === undefined || selectedRecType === "") {
      setcanMassEdit(false);
    } else {
      setcanMassEdit(true);
    }
  }, [selectedRecType]);

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {OpenDialog && (
        <ScrollDialog
          Confirm={async () => {
            setOpenDialog(false);
            const DeletedSerials = await DeleteAllSerialsByCode({
              DeleteAll: Search?.length >= 1 ? "No" : "Yes",
              Code: Search,
              ReceiverType: ReceiverTypes[selectedRecType],
            });

            const RandomInt = getRandomInt(50000);
            window.location.href = `#${RandomInt}`;

            setRefreshCompOut(!RefreshCompOut);
            setSearch("");
          }}
          Cancel={() => {
            setOpenDialog(false);
          }}
          Title={"Are you sure?"}
          Message={`You might be removing a huge number of Serials & Mac Addresses, Are you sure? Your removing, Type: [${Search?.length >= 1 ? "A set" : "All"
            }]${Search ? ` Code: ${Search}` : ""}`}
        />
      )}

      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {OpenUpload && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UploadSerial
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshCompOut(!RefreshCompOut);
            }}
            Close={() => {
              setOpenUpload(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {MassSerialEdit && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MassEditSerialsComponent
            ReceiverType={ReceiverTypes[selectedRecType]}
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshCompOut(!RefreshCompOut);
            }}
            Close={() => {
              setMassSerialEdit(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddSerial
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshCompOut(!RefreshCompOut);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditSerial
            SerialtoEdit={SerialtoEdit}
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshCompOut(!RefreshCompOut);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup || OpenUpload || MassSerialEdit ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
            setOpenUpload(false);
            setMassSerialEdit(false);
          }}
          style={{
            width: "100%",
            marginRight: "20%",
            height: "100%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Serials {"&"} Mac Addresses</h2>

            <div
              style={{
                display: "flex",
                width: 140,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {canMassEdit && (
                <CardActionArea
                  onClick={() => {
                    setMassSerialEdit(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Edit style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}

              {canAdd && (
                <CardActionArea
                  onClick={() => {
                    setPopup(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Add style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}

              {canUpload && (
                <CardActionArea
                  onClick={() => {
                    // InputFile.current.click();
                    setOpenUpload(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloudUpload
                    style={{ width: 30, height: 30, color: "primary" }}
                  />
                </CardActionArea>
              )}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              // disabled={IsSearchable}
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "lightgrey",
                marginTop: 20,
              }}
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder={"Search"}
            />

            {canDelete && (
              <CardActionArea
                className="FocusedItem"
                // disabled={canMassEdit}
                onClick={() => {
                  setDeleteAll("No");
                  setOpenDialog(true);
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: 1,
                }}
              >
                <h4>{`Delete All`}</h4>
              </CardActionArea>
            )}

            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                marginTop: "2.3%",
              }}
            >
              <Select
                style={{ borderColor: "darkgreen", borderWidth: 1 }}
                value={selectedRecType}
                onChange={(e) => {
                  console.log(e.target.value);
                  setselectedRecType(e.target.value);
                }}
                color="primary"
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  color: "red",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "red",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "red !important",
                  },
                }}
                labelStyle={{ color: "green" }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {ReceiverTypes.map((Item, Index) => {
                  return (
                    <MenuItem key={Index} value={Index}>
                      {Item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <SerialsTable
                canDelete={canDelete}
                canEdit={canEdit}
                Search={Search}
                onEdit={(row) => {
                  setSerialtoEdit(row);
                  setEditPopup(true);
                }}
                ReceiverTypes={ReceiverTypes}
                selectedRecType={selectedRecType}
                RefreshCompOut={RefreshCompOut}
                onisFilteration={(IsFilteration) => {
                  setIsSearchable(IsFilteration);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Serials;
