//Create Navigation for the react router
import { useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import {
  GetSomeObject,
  GetToken,
  SaveCurrentPage,
  SaveSomeObject,
  SaveToken,
} from "../Assets/Storage";

import { GetPrivileges, ValidateToken } from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import { Add, Details } from "@mui/icons-material";
import AddCode from "../Components/AddCode";
import EditCode from "../Components/EditCode";
import SeriesTable from "../Components/SeriesTable";
import AddSeries from "../Components/AddSeries";
import EditSeries from "../Components/EditSeries";
import SubCategoriesTable from "../Components/SubCategoriesTable";
import AddSubCategory from "../Components/AddSubCategory.js";
import EditSubCategory from "../Components/EditSubCategory";
import PeriodsTable from "../Components/PeriodsTable";
import AddPeriod from "../Components/AddPeriod";
import EditPeriod from "../Components/EditPeriod";
import CountriesTable from "../Components/CountriesTable";

const Countries = (prop) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Countries");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [PeriodToEdit, setPeriodToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canDelete, setCanDelete] = useState(false);

  const setupPrivileges = async ({ Privileges, isAdmin }) => {
    if (isAdmin) {
      setCanDelete(true);
    } else {
      const GetDelete = Privileges?.Delete?.filter?.(
        (item) => item.Name === "Countries"
      );

      setCanDelete(GetDelete[0]?.permitted);
    }
  };

  useEffect(() => {
    const Cached = GetSomeObject("GetPrivileges");
    if (Cached) {
      const data = JSON.parse(Cached);
      const Data = data ? data : {};

      setupPrivileges(Data ? Data : {});
    }

    (async () => {
      const Data = await GetPrivileges();

      SaveSomeObject("GetPrivileges", Data ? Data : {});
      setupPrivileges(Data ? Data : {});
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Countries</h2>
          </div>

          <Input
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
            }}
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={"Search"}
          />

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CountriesTable
                canDelete={canDelete}
                Search={Search}
                onEdit={(row) => {
                  setPeriodToEdit(row);
                  setEditPopup(true);
                }}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countries;
