//New react functional component
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { DeleteSubCategory, ExportSubCategories } from "../Assets/HttpBank";
import { Delete, Download, Edit, FolderOpen, ImportExport } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const SubCategoryItem = (prop) => {
  const { row, RefreshCompInside, onEdit, canDelete, canEdit, onDelete, canExport, socket } = prop;
  const navigate = useNavigate();

  const [LiveViews, setLiveViews] = useState(0)

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    socket?.emit?.("getSubCategoryById", row?._id, (data) => {
      console.log("data", data);
      setLiveViews(data ? data : 0)
    });

    socket?.on?.(`Views_${row?._id}`, (data) => {
      console.log("data V", data);
      setLiveViews(data?.LiveViews ? data?.LiveViews : 0)
    });

    return () => {
      socket?.off?.(`Views_${row?._id}`)
    }
  }, [row, socket])

  useEffect(() => {
    setLiveViews(row?.LiveViews)
  }, [row?.LiveViews])

  return (
    <TableRow>
      <TableCell style={{ minWidth: 120 }}>{row.Name}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{row?.Position}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{row.CategoryName}</TableCell>
      <TableCell
        onClick={() => {
          window.location = `/LiveViews/${row?._id}/Lives/true/sub-category`
        }}
        style={{
          minWidth: 120,
          color: "red",
          fontSize: 20,
          fontWeight: "bolder",
          cursor: "pointer"
        }}>
        {/* <div
          onClick={() => {
            window.location = `/LiveViews/${row?._id}/Lives/true/sub-category`
          }}
          style={{
            color: "red",
            fontSize: 20,
            fontWeight: "bolder",
            cursor: "pointer"
          }}
        > */}
        {LiveViews}
        {/* </div> */}
      </TableCell>

      <TableCell style={{ minWidth: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>

          {canExport && (
            <CardActionArea
              onClick={async () => {
                await ExportSubCategories(row?._id)
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Download style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canEdit && (
            <CardActionArea
              onClick={() => {
                onEdit?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Edit style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canDelete && (
            <CardActionArea
              onClick={async () => {
                onDelete?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default SubCategoryItem;
