//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import { CreateSerial, GetCodesManual, GetPeriods } from "../Assets/HttpBank";

const AddSerial = (prop) => {
  const navigate = useNavigate();
  const [Value, setValue] = useState("");
  const [Period, setPeriod] = useState(0);
  const [Points, setPoints] = useState(0);
  const [ReceiverType, setReceiverType] = useState("");
  const [Code, setCode] = useState("");

  const [Periods, setPeriods] = useState([]);

  const [isGenerate, setIsGenerate] = useState(false);

  const [Codes, setCodes] = useState([]);

  useEffect(() => {
    (async () => {
      const Codes = await GetCodesManual({});
      setCodes(Codes?.Codes ? Codes?.Codes : []);
      if (Codes?.Codes[0]) {
        setCode(Codes?.Codes[0].Code);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Periods = await GetPeriods();
      setPeriods(Periods?.Periods ? Periods?.Periods : []);
      if (Periods?.Periods[0]) {
        setPeriod(Periods?.Periods[0].Period);
        setPoints(Periods?.Periods[0].Points);
      }
    })();
  }, []);

  const Create = async () => {
    prop?.Message?.({ Message: "Creating serial...", Type: "info" });
    const CreatedCodes = await CreateSerial({
      Value,
      ReceiverType,
      Period,
      Code,
    });

    if (!CreatedCodes?.Valid) {
      prop?.Message?.({
        Message: "Couldn't create the serial.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `Serial created!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Serial or Mac Address.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={isGenerate ? "number" : "text"}
          value={Value}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={isGenerate ? "Generate Number" : "Value"}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">The code?</InputLabel>
        <Select
          onChange={(e) => {
            setCode(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Code}
          label="Age"
          // onChange={handleChange}
        >
          {Codes.map((Item) => {
            return <MenuItem value={Item?.Code}>{Item?.Code}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Period In Days?</InputLabel>
        <Select
          onChange={(e) => {
            setPeriod(e.target.value);
            setPoints(
              Periods.filter((x) => x.Period == e.target.value)[0]?.Points
            );
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Period}
          label="Age"
          // onChange={handleChange}
        >
          {Periods.map((Period) => {
            return (
              <MenuItem value={Period?.Period}>
                {Period?.Period} Days - {Period?.Points <= 0 ? "Demo" : "Paid"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          marginLeft: 10,
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Input
          style={{
            width: "100%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Receiver Type"}
          value={ReceiverType}
          onChange={(e) => {
            setReceiverType(e.target.value);
          }}
        />
      </div>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Create();
        }}
      >
        <h5>Create</h5>
      </Button>
    </Card>
  );
};

export default AddSerial;
