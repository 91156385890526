//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  GetLivesAndCount,
  GetLivesAndCountSearch,
  GetMoviesAndCount,
  GetMoviesAndCountSearch,
  GetSeriesAndCount,
  GetSeriesAndCountSearch,
  GetSettings,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import MoviesItem from "./MoviesItem";
import LivesItem from "./LivesItem";

import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import socketio from "socket.io-client";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function createData(name, index) {
  return { name, index };
}

const LivesTable = (prop) => {
  const {
    RefreshComp,
    onEdit,
    canStatus,
    Search,
    canDelete,
    canEdit,
    onCounts,
  } = prop;

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [Lives, setLives] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const RefreshSeries = async () => {
    if (NumberOfRows >= 1) {
      console.time("GetLivesAndCount");
      const Cached = GetSomeObject("GetLivesAndCount");
      if (Cached) {
        const data = JSON.parse(Cached);
        const { Count, Lives, OfflineCount, OnlineCount, Valid } = data
          ? data
          : {};

        onCounts?.(OfflineCount, OnlineCount);
        setLives(Lives);
        setCount(Count);
      }

      const Data = await GetLivesAndCount(Skip, NumberOfRows);
      console.timeEnd("GetLivesAndCount");

      const { Count, Lives, OfflineCount, OnlineCount, Valid } = Data
        ? Data
        : {};
      if (!Valid) return;

      SaveSomeObject("GetLivesAndCount", Data ? Data : {});

      onCounts?.(OfflineCount, OnlineCount);
      setLives(Lives);
      setCount(Count);
    }
  };

  useEffect(() => {
    if (Search?.length <= 0) {
      // console.log("Requesting Lives...");
      RefreshSeries();
    }
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetLivesAndCountSearch(Skip, NumberOfRows, Search);
        if (!Data) {
          return RefreshSeries();
        }

        const { Count, Lives, OfflineCount, OnlineCount, Valid } = Data
          ? Data
          : {};
        if (!Valid) return;

        onCounts?.(OfflineCount, OnlineCount);
        setLives(Lives);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  const setupSocket = async () => {
    const socket = socketio(
      `http://realtime.${Host.replace("http://", "").replace("https://", "")}`
    ).connect();

    Lives.forEach((Live) => {
      socket.on(`${Live?._id}`, ({ Status, ID }) => {
        setTimeout(() => {
          console.log(Status, ID);
          let Update = false;
          let AppliedLives = [...Lives];

          AppliedLives = AppliedLives.filter((Live) => {
            if (`${Live?._id}` === `${ID}`) {
              Update = true;
              Live.Status = Status;
            }
            return Live;
          });

          if (Update) {
            setLives([...AppliedLives]);
          }
        }, 1);
      });
    });

    Lives.forEach((Live) => {
      socket.on(`Views_${Live?._id}`, ({ LiveViews, ID, Name }) => {
        setTimeout(() => {
          console.log(LiveViews, ID, Name);
          let Update = false;
          let AppliedLives = [...Lives];

          AppliedLives = AppliedLives.filter((Live) => {
            if (`${Live?._id}` === `${ID}`) {
              Update = true;
              Live.LiveViews = LiveViews;
            }
            return Live;
          });

          if (Update) {
            setLives([...AppliedLives]);
          }
        }, 2);
      });
    });
    return socket;
  };
  useEffect(() => {
    const socketPromise = setupSocket();

    return async () => {
      const socket = await socketPromise;

      socket.off();
      socket.offAny();
      socket.close();
    };
  }, [Lives]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "100%" }}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Picture</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Views</TableCell>
            <TableCell>Live Views</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{ opacity: Lives.length <= 0 ? 0 : 1, transition: "all .6s" }}
        >
          {Lives.map((row, index) => {
            return (
              <LivesItem
                canDelete={canDelete}
                canEdit={canEdit}
                canStatus={canStatus}
                key={index}
                row={row}
                RefreshCompInside={() => {
                  setRefreshCompInside(!RefreshCompInside);
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>

      {Lives?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",

            padding: 10,
          }}
        >
          <TablePagination
            style={{ marginRight: 20 }}
            component="div"
            count={Count}
            page={Page}
            onPageChange={(c, page) => {
              setPage(page);
              setSkip(page * NumberOfRows);
              //   alert(page);
            }}
            rowsPerPage={NumberOfRows}
            rowsPerPageOptions={[6]}
          />
        </div>
      )}
    </TableContainer>
  );
};

export default LivesTable;
