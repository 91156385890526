import { Host } from "./Receiver";
import { GetToken } from "./Storage";

export const ValidateToken = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for bigo.ws/categories
  const Response = await fetch(`${Host}/Admin/ValidateToken`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Series-Count

export const DeleteSeries = async ({ SeriesID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Series`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      SeriesID: SeriesID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteMovies = async ({ MoviesID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Movies`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      MoviesID: MoviesID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteLives = async ({ LivesID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Lives`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      LivesID: LivesID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Media-Views-By-Type-ID
export const GetMediaViewsByTypeID = async (ID, Type, IsRealTime, subCategory) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Media-Views-By-Type-ID`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ID,
      Type,
      IsRealTime,
      subCategory
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Series-Episodes-Count
export const GetSeriesEpisodesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Series-Episodes-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Series-Episodes-Count-Search
export const GetSeriesEpisodesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(
    `${Host}/Admin/Series-Fragment-Episodes-Count-Search`,
    {
      method: "POST",
      body: JSON.stringify({
        Token: Token,
        Skip,
        Limit,
        Query,
      }),
      headers: {
        "content-type": "application/json",
      },
    }
  );

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Series-By-ID
export const GetSeriesByID = async (SeriesID) => {
  const Token = GetToken();
  if (!Token) return false;
  if (SeriesID?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Series-By-ID`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      SeriesID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Series-Fragment-Episodes-Count-Search
export const GetSeriesFragmentEpisodesAndCountSearch = async (
  Skip,
  Limit,
  Query,
  SeriesID
) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(
    `${Host}/Admin/Series-Fragment-Episodes-Count-Search`,
    {
      method: "POST",
      body: JSON.stringify({
        Token: Token,
        Skip,
        Limit,
        Query,
        SeriesID,
      }),
      headers: {
        "content-type": "application/json",
      },
    }
  );

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//SubCategories-Count-Search
export const GetSubCategoriesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/SubCategories-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Movies-Count
export const GetMoviesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Movies-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Movies-Count-Search
export const GetMoviesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Movies-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const RemoveServerAPI = async (ServerId) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Remove-Server`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ServerId: ServerId,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const AddServerAPI = async (Server) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Add-Server`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ...Server,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const UpdateServerAPI = async (Server) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Update-Server`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ...Server,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetServers = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Get-Servers`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetLivesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Lives-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetLivesStatus = async () => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Lives-Status`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Movies-Count-Search
export const GetLivesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Lives-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetSeriesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Series-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetSeriesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Series-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetCategoriesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Categories-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetCategoriesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Categories-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetCodesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Codes-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Export-SubCategories-With-Data
export const ExportSubCategories = async (SubCategory = "") => {
  const Token = GetToken();
  if (!Token) return false;

  window.open(
    `${Host}/Admin/Export-SubCategories-With-Data?Token=${Token}&SubCategory=${SubCategory}`,
    "blank"
  );

  return true;
};



//Codes-Export
export const ExportCodes = async (Query = "") => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Codes-Export`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Query: Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (
    Response.status === 401 ||
    Response.status === 404 ||
    Response.status === 500
  )
    return { Valid: false };

  const Data = await Response.json();

  //Download File
  const FileName = `${new Date()}.${Data?.ext ? Data?.ext : "txt"}`;
  const FileBlob = new Blob([Data?.file], {
    type:
      Data?.ext == "txt"
        ? "text/plain"
        : Data?.ext == "json"
          ? "application/json"
          : "application/csv",
  });
  //New file from FileBlob
  const FileDownloadable = new File([FileBlob], FileName, {
    type:
      Data?.ext == "txt"
        ? "text/plain"
        : Data?.ext == "json"
          ? "application/json"
          : "application/csv",
  });

  const URL = window.URL.createObjectURL(FileDownloadable);
  const Link = document.createElement("a");
  Link.href = URL;
  Link.setAttribute("download", FileName);
  document.body.appendChild(Link);
  Link.style.display = "none";
  Link.click();
  document.body.removeChild(Link);
  window.URL.revokeObjectURL(URL);

  return Data;
};

export const GetCodesAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Codes-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetClientAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Clients-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetClientAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;
  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Clients-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Serials-Count

export const GetSerialsAndMacsAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Serials-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetSerialsAndMacsAndCountSearch = async (
  Skip,
  Limit,
  Query,
  ReceiverType
) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0 && !ReceiverType) return undefined;

  const Response = await fetch(`${Host}/Admin/Serials-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
      ReceiverType: ReceiverType,
    }),
    headers: {
      "content-type": "application/json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Expires: 0,
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  // alert(Response.status);

  console.log(
    `{"Skip": ${Skip}, "Limit": ${Limit}, "Query": "${Query}", "Token": "${Token}"}`,
    `${Host}/Admin/Serials-Count-Search`,
    Data
  );
  return Data;
};

export const CreatePeriod = async ({ Period, Points }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Period`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Period,
      Points,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Block-Country
export const BlockCountry = async ({ CountryID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Block-Country`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      CountryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Countries-Count
export const GetCountriesAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Countries-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetCountriesAndCountSearch = async (Skip, Limit, Query = "") => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Countries-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditPeriodRequest = async ({ Period, Points, ID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Period`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Period,
      Points,
      ID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeletePeriod = async ({ ID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Period`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetPeriods = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Periods`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Users-Count
export const GetUsersAndCount = async (Skip, Limit) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Users-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetUsersAndCountSearch = async (Skip, Limit, Query) => {
  const Token = GetToken();
  if (!Token) return false;
  if (Query?.length <= 0) return undefined;

  const Response = await fetch(`${Host}/Admin/Users-Count-Search`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteUser = async ({ UserID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-User`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      UserID: UserID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//SubCategories-Count
export const GetSubCategoriesCount = async ({ Skip = 0, Limit = 1000 }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/SubCategories-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Import-SubCategories-With-Data
export const UploadSubCategoriesWithData = async ({ SubCategory }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Import-SubCategories-With-Data`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      SubCategory,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 401) return { Valid: false };

  if (Response.status === 409) return { Valid: false, message: "The sub category is already imported." };

  if (Response.status === 412) return { Valid: false, message: "The position is in conflict, please change the position to a free spot and retry." };

  const Data = await Response.json();
  return Data;
};

//Create-Series-Episodes-Mass
export const UploadEpisodes = async ({ Episodes }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Series-Episodes-Mass`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Episodes,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Create-Lives-Mass
export const UploadLives = async ({ Data }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Lives-Mass`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Data,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const ResponseData = await Response.json();
  return ResponseData;
};

//Edit-Settings
export const EditSettings = async ({ Settings }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Settings`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Settings,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Settings
export const GetSettings = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Settings`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Codes-Manual
export const GetCodesManual = async ({ Skip = 0, Limit = 20 }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Codes-Manual`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetSubCategoriesLivesCount = async ({
  Skip = 0,
  Limit = 1000,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/SubCategories-Lives-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Categories-By-Type
export const GetCategoriesByType = async (Type) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Categories-By-Type`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Type,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//SubCategories-By-Category
export const GetSubCategoriesByCategory = async ({ CategoryID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/SubCategories-By-Category`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//SubCategories-Movies-Count
export const GetSubCategoriesMoviesCount = async ({
  Skip = 0,
  Limit = 1000,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/SubCategories-Movies-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetSubCategoriesSeriesCount = async ({
  Skip = 0,
  Limit = 1000,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/SubCategories-Series-Count`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Skip,
      Limit,
    }),
    headers: {
      "content-type": "application/json",
    },
  });
  if (Response.status === 401) return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Edit-Client
export const EditClientRequest = async ({ Period, Blocked, ClientID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Client`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Period,
      Blocked,
      ClientID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditEpisodeRequest = async ({ Name, Url, Series, EpisodeID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Episode`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Url,
      Series,
      EpisodeID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Edit-Series
export const EditSeriesRequest = async ({
  Name,
  Picture,
  SubCategory,
  Featured,
  SeriesID,
  CategoryID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Series`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      SeriesID,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditMoviesRequest = async ({
  Name,
  Picture,
  SubCategory,
  Featured,
  Url,
  MoviesID,
  CategoryID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Movies`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      Url,
      MoviesID,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditLivesRequest = async ({
  Name,
  Picture,
  SubCategory,
  Featured,
  Url,
  LivesID,
  CategoryID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Lives`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      Url,
      LivesID,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Last-SubCategory
export const GetLastSubCategoryPosition = async ({ Category }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Last-SubCategory`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Category,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Create-SubCategory
export const CreateSubCategory = async ({ Name, Category, Position }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-SubCategory`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Category,
      Position,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Create-Episode
export const CreateEpisode = async ({ Name, Url, Series }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-Episode`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Url,
      Series,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Create-User
export const CreateUser = async ({
  Username,
  Password,
  Balance,
  Privileges,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-User`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Username,
      Password,
      Balance,
      Privileges,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditUserRequest = async ({
  Username,
  Password,
  Balance,
  Privileges,
  UserID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-User`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Username,
      Password,
      Balance,
      Privileges,
      UserID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const CreateMovies = async ({
  Name,
  Picture,
  SubCategory,
  Featured,
  Url,
  Category,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-Movies`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      Url,
      Category,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const CreateLives = async ({
  Name,
  Picture = "",
  SubCategory,
  Featured,
  Url,
  Category,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-Lives`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      Url,
      Category,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const CreateSeries = async ({
  Name,
  Picture,
  SubCategory,
  Featured,
  Category,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categoriesz
  const Response = await fetch(`${Host}/Admin/Create-Series`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Picture,
      SubCategory,
      Featured,
      Category,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const CreateCodes = async ({
  Value,
  Limit,
  Company,
  ClientType,
  isGenerate,
  Period,
  SubCategories,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Codes`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Value,
      Limit,
      Company,
      ClientType,
      isGenerate,
      Period,
      SubCategories,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Delete-Episode
export const DeleteEpisode = async ({ EpisodeID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Episode`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      EpisodeID: EpisodeID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteCodes = async ({ CodeID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Code`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      CodeID: CodeID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Delete-All-Serial

export const DeleteAllSerialsByCode = async ({
  Code,
  DeleteAll = "No",
  ReceiverType,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-All-Serial`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Code,
      DeleteAll,
      ReceiverType,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Delete-Mass-Client
export const DeleteAllClients = async ({ Query = "" }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Mass-Client`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Query,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteAllCodes = async ({ User, DeleteType }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-All-Code`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      User,
      DeleteType,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditCodes = async ({
  Value,
  Limit,
  Company,
  ClientType,
  isGenerate,
  Period,
  _id,
  SubCategories,
}) => {
  const Token = GetToken();
  if (!Token) return false;
  const CodeID = _id;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Code`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Value,
      Limit,
      Company,
      ClientType,
      isGenerate,
      Period,
      CodeID,
      SubCategories,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Create-Serials-Mass

export const CreateSerialsMass = async ({ Serials }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Serials-Mass`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Serials,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteSerial = async ({ SerialID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Serial`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      SerialID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  const JsonResponse = await Response.json();

  const Data = JsonResponse ? JsonResponse : {};
  if (Response.status === 400 || Response.status === 401)
    return { Valid: false, ...{ Data } };

  return Data;
};

export const CreateSerial = async ({ Value, Period, ReceiverType, Code }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Serial`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Value,
      Period,
      ReceiverType,
      Code,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const DeleteClient = async ({ ClientID }) => {
  //Delete-Client
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Delete-Client`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      ClientID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Edit-Mass-Serials-By-ReceiverType
export const MassEditSerials = async ({
  Period,
  ReceiverType,
  ReceiverTypeToEdit,
  Code,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(
    `${Host}/Admin/Edit-Mass-Serials-By-ReceiverType`,
    {
      method: "POST",
      body: JSON.stringify({
        Token: Token,
        Period,
        ReceiverType,
        Code,
        ReceiverTypeToEdit,
      }),
      headers: {
        "content-type": "application/json",
      },
    }
  );

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const EditSerialCode = async ({
  Value,
  Period,
  ReceiverType,
  Code,
  SerialID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Serial`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Value,
      Period,
      ReceiverType,
      Code,
      SerialID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//delete-SubCategory
export const DeleteSubCategory = async ({ SubCategoryID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/delete-SubCategory`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      SubCategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  if (Response.status === 403) return { Code: 403 };

  const Data = await Response.json();
  return Data;
};

export const DeleteCategory = async ({ CategoryID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Delete-Category`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  if (Response.status === 403) return { Code: 403 };

  const Data = await Response.json();
  return Data;
};

//Create-Category

export const CreateCategory = async ({ Name, Type, Logo }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Create-Category`, {
    method: "POST",
    body: JSON.stringify({
      Token: Token,
      Name,
      Type,
      Logo,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Edit-SubCategory
export const EditSubCategoryRequest = async ({
  Name,
  Category,
  Position,
  SubCategoryID,
}) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-SubCategory`, {
    method: "POST",
    body: JSON.stringify({
      Token,
      Name,
      Category,
      Position,
      SubCategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Edit-Category
export const EditCategory = async ({ Name, Type, Logo, CategoryID }) => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Edit-Category`, {
    method: "POST",
    body: JSON.stringify({
      Token,
      Name,
      Type,
      Logo,
      CategoryID,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const ProxyImage = async (ImageURL = "") => {
  if (!ImageURL?.includes?.("http")) return false;

  const Token = GetToken();
  if (!Token) return false;
  const Response = await fetch(`${Host}/Admin/ProxyImage?ImageURL=${ImageURL}`);

  return Response;
};

//Points
export const GetPoints = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Points`, {
    method: "POST",
    body: JSON.stringify({
      Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

export const GetPrivileges = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Privileges`, {
    method: "POST",
    body: JSON.stringify({
      Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};
//Get-Receiver-Types

export const GetReceiverTypes = async () => {
  const Token = GetToken();
  if (!Token) return false;

  //Request for localhost:3000/categories
  const Response = await fetch(`${Host}/Admin/Get-Receiver-Types`, {
    method: "POST",
    body: JSON.stringify({
      Token,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Get-Serials-By-Receiver-Type
export const GetSerialsByReceiverType = async (
  ReceiverType = undefined,
  Skip,
  NumberOfRows
) => {
  const Token = GetToken();
  if (!Token) return false;

  const Response = await fetch(`${Host}/Admin/Get-Serials-By-Receiver-Type`, {
    method: "POST",
    body: JSON.stringify({
      Token,
      ReceiverType,
      Limit: NumberOfRows,
      Skip: Skip,
    }),
    headers: {
      "content-type": "application/json",
    },
  });

  if (Response.status === 400 || Response.status === 401)
    return { Valid: false };

  const Data = await Response.json();
  return Data;
};

//Download-Expired-Clients
export const DownloadExpiredClients = async (type = "excel") => {
  const Token = GetToken();
  if (!Token) return false;

  window.open(
    `${Host}/Admin/Download-Expired-Clients?Token=${Token}&type=${type}`,
    "blank"
  );

  return true;
};
