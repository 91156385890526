//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, Host, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import { GetPrivileges, ValidateToken } from "../Assets/HttpBank";
import { Add, CloudUpload, Details } from "@mui/icons-material";
import LivesTable from "../Components/LivesTable";
import EditLives from "../Components/EditLives";
import AddLives from "../Components/AddLives";

import UploadLivesForm from "../Components/UploadLivesForm";
import socketio from "socket.io-client";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

const Lives = (prop) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Lives");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [LivesToEdit, setLivesToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [canUpload, setcanUpload] = useState(false);

  const [canStatus, setcanStatus] = useState(false);

  const setupPrivileges = async ({ Privileges, isAdmin }) => {
    if (isAdmin) {
      setCanAdd(true);
      setCanEdit(true);
      setCanDelete(true);
      setcanUpload(true);
      setcanStatus(true);
    } else {
      const GetCreate = Privileges?.Create?.filter?.(
        (item) => item.Name === "Lives"
      );

      setCanAdd(GetCreate[0]?.permitted);

      const GetEdit = Privileges?.Edit?.filter?.(
        (item) => item.Name === "Lives"
      );

      setCanEdit(GetEdit[0]?.permitted);

      const GetDelete = Privileges?.Delete?.filter?.(
        (item) => item.Name === "Lives"
      );

      setCanDelete(GetDelete[0]?.permitted);

      const GetUpload = Privileges?.Upload?.filter?.(
        (item) => item.Name === "Lives"
      );

      setcanUpload(GetUpload[0]?.permitted);

      const GetStatus = Privileges?.Status?.filter?.(
        (item) => item.Name === "Lives"
      );

      setcanStatus(GetStatus[0]?.permitted);
    }
  };
  useEffect(() => {
    const Cached = GetSomeObject("GetPrivileges");
    if (Cached) {
      const data = JSON.parse(Cached);
      const Data = data ? data : {};

      setupPrivileges(Data ? Data : {});
    }

    (async () => {
      const Data = await GetPrivileges();

      SaveSomeObject("GetPrivileges", Data ? Data : {});
      setupPrivileges(Data ? Data : {});
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  const [ShowUploadForm, setShowUploadForm] = useState(false);

  const [WatchingNow, setWatchingNow] = useState(0);

  const setupSocket = async () => {
    const socket = socketio(
      `http://realtime.${Host.replace("http://", "").replace("https://", "")}`
    ).connect();

    socket.on("watchingNow", (data) => {
      setWatchingNow(data > 0 ? data : 0);
    });

    socket.emit("getWatchingNow");
    return socket;
  };

  useEffect(() => {
    const socketPromise = setupSocket();

    return async () => {
      const socket = await socketPromise;

      socket.off();
      socket.offAny();
      socket.close();
    };
  }, []);

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "scroll",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "lightgrey",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddLives
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditLives
            LivesToEdit={LivesToEdit}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {ShowUploadForm && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UploadLivesForm
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setShowUploadForm(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup || ShowUploadForm ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
            setShowUploadForm(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          // backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            // background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            // overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <CardActionArea
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 15,
                width: "auto",
                padding: 10,
                height: "auto",
                borderRadius: 10,
              }}
            >
              <h2
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
              >
                Lives ~
              </h2>
              <h2
                style={{
                  color: "red",
                  marginLeft: 5,
                  transition: "color 0.5s ease-in-out",
                  animation: `${WatchingNow > 0 ? "blink" : "none"
                    } 1s infinite`,
                }}
              >
                {WatchingNow} Now watching!
              </h2>
            </CardActionArea>
            {/* <h2 style={{ color: "red", fontSize: 15 }}>
              Offline: {OfflineCount}
            </h2>
            <h2 style={{ color: "green", fontSize: 15 }}>
              Online: {OnlineCount}
            </h2> */}

            <div
              style={{
                display: "flex",
                width: 100,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {canAdd && (
                <CardActionArea
                  onClick={() => {
                    setPopup(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Add style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}

              {canUpload && (
                <CardActionArea
                  onClick={() => {
                    setShowUploadForm(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloudUpload
                    style={{ width: 30, height: 30, color: "primary" }}
                  />
                </CardActionArea>
              )}
            </div>
          </div>
          <Input
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
            }}
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={"Search"}
          />

          <div style={{ width: "100%", maxHeight: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: 20,
              }}
            >
              <LivesTable
                canDelete={canDelete}
                canEdit={canEdit}
                canStatus={canStatus}
                Search={Search}
                onEdit={(row) => {
                  setLivesToEdit(row);
                  setEditPopup(true);
                }}
                onCounts={(OfflineCount, OnlineCount) => { }}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lives;
