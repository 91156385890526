//Create Navigation for the react router
import { useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import {
  DeleteAllCodes,
  ExportCodes,
  GetPrivileges,
  ValidateToken,
} from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import { Add } from "@mui/icons-material";
import AddCode from "../Components/AddCode";
import EditCode from "../Components/EditCode";
import ScrollDialog from "../Components/Confirm";

//function to get random int
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}
const Codes = (prop) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Codes");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [CodetoEdit, setCodetoEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canExport, setCanExport] = useState(false);

  const [canManual, setCanManual] = useState(false);
  const [canGenerate, setCanGenerate] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();
      setIsAdmin(isAdmin);
      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
        setCanManual(true);
        setCanGenerate(true);
        setCanExport(true);
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanDelete(GetDelete[0]?.permitted);

        const GetManual = Privileges?.Manual?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanManual(GetManual[0]?.permitted);

        const GetGenerate = Privileges?.Generate?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanGenerate(GetGenerate[0]?.permitted);

        const GetExport = Privileges?.Export?.filter?.(
          (item) => item.Name === "Codes"
        );

        setCanExport(GetExport[0]?.permitted);
      }
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  const [RefreshPoints, setRefreshPoints] = useState(false);

  const [OpenDialog, setOpenDialog] = useState(false);

  const [DeleteType, setDeleteType] = useState("");
  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {OpenDialog && (
        <ScrollDialog
          Confirm={async () => {
            setOpenDialog(false);
            const DeleteAll = await DeleteAllCodes({
              DeleteType: DeleteType,
              User: Search,
            });

            const RandomInt = getRandomInt(50000);
            window.location.href = `#${RandomInt}`;
            setRefreshComp(!RefreshComp);
            setSearch("");
          }}
          Cancel={() => {
            setOpenDialog(false);
          }}
          Title={"Are you sure?"}
          Message={`You might be removing a huge number of codes, Are you sure? Your removing, Type: [${DeleteType}]${
            Search ? ` User: ${Search}` : ""
          }`}
        />
      )}

      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddCode
            canManual={canManual}
            canGenerate={canGenerate}
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditCode
            canManual={canManual}
            canGenerate={canGenerate}
            CodetoEdit={CodetoEdit}
            Refresh={() => {
              const RandomInt = getRandomInt(50000);
              window.location.href = `#${RandomInt}`;
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar
          RefreshPoints={RefreshPoints}
          UpdateBalance={RefreshComp}
        /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Codes</h2>

            {canAdd && (
              <CardActionArea
                onClick={() => {
                  setPopup(true);
                }}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Add style={{ width: 30, height: 30, color: "primary" }} />
              </CardActionArea>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Input
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "lightgrey",
                marginTop: 20,
              }}
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder={"Search"}
            />

            {canDelete && (
              <CardActionArea
                className="FocusedItem"
                onClick={() => {
                  setDeleteType("Generate");
                  setOpenDialog(true);
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h4>{`Delete All${isAdmin ? ` Generated` : ""}`}</h4>
              </CardActionArea>
            )}

            {canDelete && isAdmin && (
              <CardActionArea
                className="FocusedItem"
                onClick={() => {
                  setDeleteType("Manual");
                  setOpenDialog(true);
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h4>Delete All Manual</h4>
              </CardActionArea>
            )}

            {canExport && (
              <CardActionArea
                className="FocusedItem"
                onClick={async () => {
                  const { Valid } = await ExportCodes(Search);
                  if (Valid) {
                    setMessage({
                      Type: "success",
                      Message: `Exported`,
                    });
                  } else {
                    setMessage({
                      Type: "error",
                      Message: `Failed to Export`,
                    });
                  }
                }}
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 20,
                  marginLeft: 20,
                  backgroundColor: `rgb(220,220,220,0.5)`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h4>Export</h4>
              </CardActionArea>
            )}
          </div>

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CodesTable
                canDelete={canDelete}
                canEdit={canEdit}
                Search={Search}
                onDeleted={() => {
                  const RandomInt = getRandomInt(50000);
                  window.location.href = `#${RandomInt}`;
                  setRefreshPoints(!RefreshPoints);
                }}
                onEdit={(row) => {
                  setCodetoEdit(row);
                  setEditPopup(true);
                  setRefreshComp(!RefreshComp);
                }}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Codes;
