//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import { EditClientRequest, GetPeriods } from "../Assets/HttpBank";

const EditClient = (prop) => {
  const navigate = useNavigate();

  const [Period, setPeriod] = useState(0);
  const [Periods, setPeriods] = useState([]);

  const [Blocked, setBlocked] = useState(prop?.ClientToEdit.Blocked);

  useEffect(() => {
    (async () => {
      const Periods = await GetPeriods();
      setPeriods(Periods?.Periods ? Periods?.Periods : []);
      if (Periods?.Periods) {
        setPeriod(
          Periods?.Periods.filter(
            (p) => p.Period === prop?.ClientToEdit.Period
          )[0]?.Period
        );
      }
    })();
  }, []);

  const Edit = async () => {
    prop?.Message?.({ Message: "Editing Series...", Type: "info" });
    const EditClient = await EditClientRequest({
      Period,
      Blocked,
      ClientID: prop?.ClientToEdit?._id,
    });

    if (!EditClient?.Valid) {
      prop?.Message?.({
        Message: "Couldn't Edit the Client.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `Client Edited!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Edit Client.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Period In Days?</InputLabel>
        <Select
          onChange={(e) => {
            setPeriod(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Period}
          label="Age"
          // onChange={handleChange}
        >
          {Periods.map((Period) => {
            return (
              <MenuItem value={Period?.Period}>
                {Period?.Period} Days - {Period?.Points <= 0 ? "Demo" : "Paid"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Block?</InputLabel>
        <Select
          onChange={(e) => {
            setBlocked(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Blocked}
          // onChange={handleChange}
        >
          {[
            { Name: "Block", Value: true },
            { Name: "UnBlock", Value: false },
          ].map((Item) => {
            return <MenuItem value={Item?.Value}>{Item?.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Edit();
        }}
      >
        <h5>Edit</h5>
      </Button>
    </Card>
  );
};

export default EditClient;
