//Create Navigation for the react router
import { useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import { GetPrivileges, ValidateToken } from "../Assets/HttpBank";
import EditCategory from "../Components/EditCategory";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import { Add } from "@mui/icons-material";

import AddCategory from "../Components/AddCategory";
import AllPrivileges from "../Assets/AllPrivileges";
const Categories = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Categories");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [CategorytoEdit, setCategorytoEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();

      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Categories"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Categories"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Categories"
        );

        setCanDelete(GetDelete[0]?.permitted);
      }
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };
  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddCategory
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditCategory
            CategorytoEdit={CategorytoEdit}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Categories</h2>

            {canAdd && (
              <CardActionArea
                onClick={() => {
                  setPopup(true);
                }}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Add style={{ width: 30, height: 30, color: "primary" }} />
              </CardActionArea>
            )}
          </div>
          <Input
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
            }}
            placeholder={"Search"}
          />

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CategoriesTable
                canDelete={canDelete}
                canEdit={canEdit}
                onEdit={(row) => {
                  setCategorytoEdit(row);
                  setEditPopup(true);
                }}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
