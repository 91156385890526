import { ArrowDownward, ArrowForward, StarBorder } from "@mui/icons-material";
import {
  CardActionArea,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NavItem = (prop = {}) => {
  const { Path, Name, isShowing, Tabs, onClick } = prop;
  const navigate = useNavigate();

  const [Open, setOpen] = useState(isShowing);

  const [Show, setShow] = useState(false);
  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, []);
  return (
    <div
      // className="SideBarItem"
      style={{
        width: "90%",
        opacity: !Show ? 0 : 1,
        transition: "all .6s",
      }}
    >
      <CardActionArea
        className="SideBarItem"
        onClick={async () => {
          setOpen(!Open);
          onClick?.(!isShowing);
          //Create Session
          // const { id: SessionId } = await CreateSession(id, "Series");
          // if (Path) {
          //   navigate(`${Path}`);
          // }
        }}
        style={{
          width: "100%",
          height: 40,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 10,
          marginTop: 10,
          padding: 10,
          backgroundColor:
            window?.location?.pathname === `/${Path}`
              ? `black`
              : undefined,
        }}
      >
        <Typography
          style={{
            color:
              window?.location?.pathname === `/${Path}` ? "black" : "white",
            fontSize: 15,
            fontWeight: "bold",
            marginLeft: 10,
          }}
        >
          {Name}
        </Typography>

        {isShowing ? (
          <ArrowForward style={{ width: 10, height: 10 }} />
        ) : (
          <ArrowDownward style={{ width: 10, height: 10 }} />
        )}
      </CardActionArea>

      <Collapse
        style={{
          width: "100%",
        }}
        in={isShowing}
        timeout="auto"
        unmountOnExit
      >
        {Tabs.map((Tab, Index) => {
          const { Name, Path } = Tab;
          return (
            <CardActionArea
              className="SideItem"
              key={Index}
              onClick={async () => {
                setOpen(!Open);
                //Create Session
                // const { id: SessionId } = await CreateSession(id, "Series");
                if (Path) {
                  navigate(`${Path}`);
                }
              }}
              style={{
                width: "90%",
                marginLeft: 10,
                height: 40,
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: 10,
                marginTop: 10,
                padding: 10,
                backgroundColor:
                  window?.location?.pathname === Path
                    ? `black`
                    : undefined,
              }}
            >
              <Typography
                style={{
                  color:
                    window?.location?.pathname === Path ? "white" : "white",
                  fontSize: 15,
                  fontWeight: "bold",
                  marginLeft: 10,
                }}
              >
                {Name}
              </Typography>
            </CardActionArea>
          );
        })}
      </Collapse>

      <div
        style={{
          width: "100%",
          height: 0.3,
          backgroundColor: "gainsboro",
          marginTop: 10,
        }}
      />
    </div>
  );
};

export default NavItem;
