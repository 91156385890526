//New react functional component
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  BlockCountry,
  DeletePeriod,
  DeleteSubCategory,
} from "../Assets/HttpBank";
import { Delete, Edit, Block, Undo } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const CountryItem = (prop) => {
  const { row, RefreshCompInside, onEdit, canDelete } = prop;
  const navigate = useNavigate();

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);

  // alert(row.Blocked);
  // const [Blocked, setBlocked] = useState(row.Blocked ? true : false);

  return (
    <TableRow>
      <TableCell style={{ minWidth: 120 }}>{row.Name}</TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {row.Blocked ? "Yes" : "No"}
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {canDelete && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CardActionArea
              onClick={async () => {
                if (
                  !window.confirm(
                    "Are you sure you wish to update this country?"
                  )
                )
                  return;
                // setBlocked(!Blocked);
                const CategoryDeleteAction = await BlockCountry({
                  CountryID: row._id,
                });

                if (CategoryDeleteAction.Code === 403) {
                  // setBlocked(!Blocked);
                  return alert("Can't block this Country, for some reason.");
                }
                RefreshCompInside?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {row.Blocked ? (
                <Undo style={{ width: 20, height: 20, color: "primary" }} />
              ) : (
                <Block style={{ width: 20, height: 20, color: "primary" }} />
              )}
            </CardActionArea>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CountryItem;
