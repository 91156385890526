//New react functional component
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
  Tooltip,
} from "@mui/material";
import {
  DeleteLives,
  DeleteMovies,
  DeleteSeries,
  RemoveServerAPI,
} from "../Assets/HttpBank";
import { Delete, Edit, FolderOpen } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const Statuses = ["green", "orange", "red", "#01033A"];

async function fetchWithTimeout(resource, options = {}) {
  try {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const ServerItem = (prop) => {
  const {
    row,
    RefreshCompInside,
    onEdit,
    canDelete,
    canStatus,
    canEdit,
    Servers,
  } = prop;
  // const navigate = useNavigate();

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);

  const [Status, setStatus] = useState(0);

  const CheckStatus = async () => {
    try {
      const start = new Date();
      const data = await fetchWithTimeout(
        `http://${row?.IP}:${row?.PORT}/Ping`,
        {
          method: "GET",
          "Content-Type": "application/json",
          // Accept: "application/json",
          // mode: "no-cors",
          // mode: "cors",
          // credentials: "include",
          // "Access-Control-Allow-Headers": "Content-Type, Authorization",
        }
      );

      if (!data || data.status >= 500) return setStatus(3);

      const end = new Date();
      let Difference = end.getTime() - start.getTime();

      if (Difference >= 7000) {
        setStatus(2);
      } else if (Difference >= 5000) {
        setStatus(1);
      } else {
        setStatus(0);
      }
    } catch (error) {
      setStatus(3);
      console.error(error);
    }
  };
  useEffect(() => {
    CheckStatus();
    const intervalChecker = setInterval(() => {
      CheckStatus();
    }, 4000);

    return () => clearInterval(intervalChecker);
  }, []);
  return (
    <TableRow>
      {/* <TableCell style={{ minWidth: 120 }}>
        <img
          alt={""}
          src={
            row?.Picture?.includes?.("http")
              ? `${Host}/Admin/ProxyImage?ImageURL=${row.Picture}`
              : row.Picture
          }
          width={110}
          height={110}
        />
      </TableCell> */}
      <TableCell style={{ minWidth: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {canStatus && (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                backgroundColor: Statuses[Status],
                marginRight: 10,
              }}
            />
          )}
          {row.Name} - {row?.IP}
        </div>
      </TableCell>
      {/* <TableCell
        style={{
          minWidth: 120,
        }}
      >
        <div
          onClick={() => {
            window.location = `/LiveViews/${row?._id}/Lives/false`;
          }}
          style={{
            fontSize: 20,
            fontWeight: "bolder",
            cursor: "pointer",
          }}
        >
          {row?.Views}
        </div>
      </TableCell> */}
      <TableCell
        className="FocusedItem"
        style={{
          minWidth: 120,
        }}
      >
        <div
          onClick={() => {
            window.location = `/LiveViews/Server:${row?.IP}/Lives/true`;
          }}
          style={{
            color: "red",
            fontSize: 20,
            fontWeight: "bolder",
            cursor: "pointer",
          }}
        >
          {Servers[row?.IP] ? Servers[row?.IP] : 0}
        </div>
      </TableCell>
      <TableCell className="FocusedItem" style={{ minWidth: 120 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {canEdit && (
            <CardActionArea
              onClick={() => {
                onEdit?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Edit style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canDelete && (
            <CardActionArea
              onClick={async () => {
                if (
                  !window.confirm("Are you sure you wish to delete this item?")
                )
                  return;

                const ServerDeleteAction = await RemoveServerAPI(row._id);

                if (ServerDeleteAction.Code === 403) {
                  return alert("Can't delete this Server, for some reason.");
                }
                RefreshCompInside?.(row._id);
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ServerItem;
