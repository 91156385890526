//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  DeleteUser,
  GetSeriesEpisodesAndCount,
  GetSeriesEpisodesAndCountSearch,
  GetSeriesFragmentEpisodesAndCountSearch,
  GetSettings,
  GetUsersAndCount,
  GetUsersAndCountSearch,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import SeriesEpisodes from "../Pages/SeriesEpisodes";
import SeriesEpisodeItem from "./SeriesEpisodeItem";
import UserItem from "./UserItem";
import ScrollDialog from "./Confirm";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function createData(name, index) {
  return { name, index };
}

const UsersTable = (prop) => {
  const { RefreshComp, onEdit, Search, canDelete, canEdit } = prop;
  const navigate = useNavigate();
  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [Users, setUsers] = useState([]);
  const [Count, setCount] = useState(0);

  const RefreshCodes = async () => {
    if (NumberOfRows >= 1) {
      const Data = await GetUsersAndCount(Skip, NumberOfRows);
      const { Count, Users, Valid } = Data ? Data : {};
      if (!Valid) return;
      setUsers(Users);
      setCount(Count);
    }
  };

  useEffect(() => {
    RefreshCodes();
  }, [Skip, NumberOfRows, RefreshComp]);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetUsersAndCountSearch(Skip, NumberOfRows, Search);
        if (!Data) {
          return RefreshCodes();
        }

        const { Count, Users, Valid } = Data ? Data : {};
        if (!Valid) return;
        setUsers(Users);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, Search]);

  const [Open, setOpen] = useState(false);
  const [IdToAction, setIdToAction] = useState("");
  return (
    <TableContainer component={Paper}>
      {Open && (
        <ScrollDialog
          Confirm={async () => {
            const DeleteCode = await DeleteUser({ UserID: IdToAction });
            RefreshCodes();
            setOpen(false);
          }}
          Cancel={() => {
            setOpen(false);
          }}
          Title={"Are you sure?"}
          Message={
            "You are removing an active user that may have been used by an individuals. Do you want to continue?"
          }
        />
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Username</TableCell>
            <TableCell align="left">Current Balance</TableCell>
            <TableCell align="left">Started Balance</TableCell>
            <TableCell align="left">Series Inserted</TableCell>
            <TableCell align="left">Episodes Inserted</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{ opacity: Users.length <= 0 ? 0 : 1, transition: "all .6s" }}
        >
          {Users.map((row, index) => (
            <UserItem
              canDelete={canDelete}
              canEdit={canEdit}
              onDelete={() => {
                setOpen(true);
                setIdToAction(row._id);
              }}
              onEdit={() => {
                onEdit?.(row);
              }}
              key={index}
              row={row}
            />
          ))}
        </TableBody>

        <div
          style={{
            width: "100%",
            display: "flex",

            alignItems: "center",
            justifyContent: "center",

            padding: 10,
          }}
        >
          <TablePagination
            component="div"
            count={Count}
            page={Page}
            onPageChange={(c, page) => {
              setPage(page);
              setSkip(page * NumberOfRows);
              //   alert(page);
            }}
            rowsPerPage={NumberOfRows}
            rowsPerPageOptions={[6]}
          />
        </div>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
