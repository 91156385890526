//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import { CreateCategory } from "../Assets/HttpBank";

const AddCode = (prop) => {
  const navigate = useNavigate();
  const [Name, setName] = useState("");
  const [Type, setType] = useState("Series");
  const [Logo, setLogo] = useState("");

  const Create = async () => {
    prop?.Message?.({ Message: "Creating Category...", Type: "info" });
    const CreatedCodes = await CreateCategory({
      Name,
      Type,
      Logo,
    });

    if (!CreatedCodes?.Valid) {
      prop?.Message?.({
        Message: "Couldn't create the Category.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `Category created!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Category.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 30,
        }}
      >
        <Input
          type={"text"}
          value={Name}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Name"}
          onInput={(e) => {
            e.target.value = e.target.value.toString().slice(0, 13);
          }}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Type?</InputLabel>
        <Select
          onChange={(e) => {
            setType(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Type}
          // onChange={handleChange}
        >
          {["Series", "Movies", "Lives"].map((Type) => {
            return <MenuItem value={Type}>{Type}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <Input
        type="file"
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginLeft: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        onChange={(e) => {
          //Convert the file to base64
          const reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            //Set the value of the input
            // console.log(reader.result);
            setLogo(reader.result);
          };
        }}
      />

      <h5 style={{ marginTop: 50, color: "orange", fontWeight: "bolder" }}>
        Please pay attention to the following: Type, And the Logo.
      </h5>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Create();
        }}
      >
        <h5>Create</h5>
      </Button>
    </Card>
  );
};

export default AddCode;
