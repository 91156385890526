//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import {
  CreatePeriod,
  CreateSubCategory,
  GetCategoriesAndCount,
} from "../Assets/HttpBank";

const AddPeriod = (prop) => {
  const navigate = useNavigate();
  const [Period, setPeriod] = useState();
  const [Points, setPoints] = useState();

  const Create = async () => {
    prop?.Message?.({ Message: "Creating Period...", Type: "info" });
    const CreatedSeries = await CreatePeriod({
      Period,
      Points,
    });

    if (!CreatedSeries?.Valid) {
      prop?.Message?.({
        Message: "Couldn't create the Period.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `Period created!`,
      Type: "success",
    });

    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Period.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Input
        type={"Number"}
        value={Period}
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        placeholder={"Period In Days"}
        onChange={(e) => {
          setPeriod(e.target.value);
        }}
      />

      <Input
        type={"Number"}
        value={Points}
        style={{
          width: "90%",
          height: 50,
          backgroundColor: "#e6e6e6",
          padding: 10,
          marginTop: 30,
          marginBottom: 30,
          borderRadius: 10,
        }}
        disableUnderline={true}
        placeholder={"Points (Price)"}
        onChange={(e) => {
          setPoints(e.target.value);
        }}
      />

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          Create();
        }}
      >
        <h5>Create</h5>
      </Button>
    </Card>
  );
};

export default AddPeriod;
