//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Code, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import {
  GetPrivileges,
  GetSeriesByID,
  UploadEpisodes,
  ValidateToken,
} from "../Assets/HttpBank";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import CodesTable from "../Components/CodesTable";
import { Add, CloudUpload, Details } from "@mui/icons-material";
import AddCode from "../Components/AddCode";
import EditCode from "../Components/EditCode";
import SeriesTable from "../Components/SeriesTable";
import AddSeries from "../Components/AddSeries";
import EditSeries from "../Components/EditSeries";
import EpisodesTable from "../Components/EpisodesTable";
import AddEpisode from "../Components/AddEpisode";
import EditEpisode from "../Components/EditEpisode";

import xml2js from "xml2js";

let parser = new xml2js.Parser();

const SeriesEpisodes = (prop) => {
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const Series = params.get("Series") ? params.get("Series") : undefined;

  const [onDeletedRefresh, setonDeletedRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/SeriesEpisodes");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [EpisodeToEdit, setEpisodeToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [canUpload, setcanUpload] = useState(false);

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();

      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
        setcanUpload(true);
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Series Episodes"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Series Episodes"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Series Episodes"
        );

        setCanDelete(GetDelete[0]?.permitted);

        const GetUpload = Privileges?.Upload?.filter?.(
          (item) => item.Name === "Series Episodes"
        );

        setcanUpload(GetUpload[0]?.permitted);
      }
    })();
  }, [, onDeletedRefresh]);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState(Series ? Series : "");

  const [RefreshCompOut, setRefreshCompOut] = useState(false);

  const [EpisodeName, setEpisodeName] = useState("");
  const [Url, setUrl] = useState("");
  useEffect(() => {
    (async () => {
      const EpisodeDetails = await GetSeriesByID(Series);
      if (EpisodeDetails?.Valid) {
        const Name = EpisodeDetails?.Episode?.Name;
        let NameArray = [];
        if (Name.includes("حلقة")) {
          NameArray = Name.split("حلقة ");
        } else {
          NameArray = Name.split("EP");
        }

        const EpisodeNumber = Number(NameArray[NameArray.length - 1]) + 1;
        const NewEpisodeName = Name.replace(
          new RegExp(EpisodeNumber - 1, "gim"),
          EpisodeNumber
        );

        const UrlArray = EpisodeDetails?.Episode?.Url?.split?.("/");
        const EpisodeUrlNum = UrlArray?.[UrlArray.length - 1];
        const EpisodeFile = EpisodeUrlNum.replace(
          new RegExp(EpisodeNumber - 1, "gim"),
          EpisodeNumber
        );

        const NewEpisodeUrl = EpisodeDetails?.Episode?.Url?.replace?.(
          new RegExp(EpisodeUrlNum, "gim"),
          EpisodeFile
        );

        setEpisodeName(NewEpisodeName ? NewEpisodeName : "");
        setUrl(NewEpisodeUrl ? NewEpisodeUrl : "");
      }
    })();
  }, [, onDeletedRefresh]);

  useEffect(() => {
    (async () => {
      const EpisodeDetails = await GetSeriesByID(Series);
      if (EpisodeDetails?.Valid) {
        const Name = EpisodeDetails?.Episode?.Name;
        let NameArray = [];
        if (Name.includes("حلقة")) {
          NameArray = Name.split("حلقة ");
        } else {
          NameArray = Name.split("EP");
        }

        const EpisodeNumber = Number(NameArray[NameArray.length - 1]) + 1;
        const NewEpisodeName = Name.replace(
          new RegExp(EpisodeNumber - 1, "gim"),
          EpisodeNumber
        );

        const UrlArray = EpisodeDetails?.Episode?.Url?.split?.("/");
        const EpisodeUrlNum = UrlArray?.[UrlArray.length - 1];
        const EpisodeFile = EpisodeUrlNum.replace(
          new RegExp(EpisodeNumber - 1, "gim"),
          EpisodeNumber
        );

        const NewEpisodeUrl = EpisodeDetails?.Episode?.Url?.replace?.(
          new RegExp(EpisodeUrlNum, "gim"),
          EpisodeFile
        );

        setEpisodeName(NewEpisodeName ? NewEpisodeName : "");
        setUrl(NewEpisodeUrl ? NewEpisodeUrl : "");
      }
    })();
  }, [RefreshComp]);

  const readFile = async (e) => {
    const file = e.target.files[0];
    //file is a file with lines separated by \n read as text
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      const text = reader.result;

      parser.parseString(text, function (err, Episodes) {
        if (err) return alert("Please check the file format.");
        const EPS = Episodes?.root?.category;
        const AllEPS = EPS[0]?.movie;
        const EpisodesToUpload = [];
        for (let index = 0; index < AllEPS?.length; index++) {
          const { name: Name, link: Url } = AllEPS[index]["$"];
          const EpisodeElement = { Series, Name, Url };
          EpisodesToUpload.push(EpisodeElement);
        }

        if (EpisodesToUpload.length <= 0)
          return alert("Please check the file contains episodes.");
        (async () => {
          const { Valid, InsertedEpisodesCount, InsertedErrors } =
            await UploadEpisodes({
              Episodes: EpisodesToUpload,
            });

          if (Valid) {
            alert(
              `Episodes Created, ${InsertedEpisodesCount} Episodes Inserted, ${InsertedErrors} Errors`
            );
          } else {
            alert("Error Creating Episodes, Check the file format");
          }

          setRefreshCompOut(!RefreshCompOut);
        })();
      });
    };
    return "";
  };

  const InputFile = createRef();

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input
        ref={InputFile}
        type="file"
        style={{ display: "none" }}
        onChange={async (e) => {
          const Data = await readFile(e);
          e.target.value = Data;
        }}
      />

      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddEpisode
            EpisodeName={EpisodeName}
            Url={Url}
            Series={Series}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditEpisode
            EpisodeToEdit={EpisodeToEdit}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
          />
        </div>
      )}

      {Popup || EditPopup ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Episodes</h2>

            <div
              style={{
                display: "flex",
                width: 100,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {canAdd && Series && (
                <CardActionArea
                  onClick={() => {
                    setPopup(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Add style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}

              {canUpload && Series && (
                <CardActionArea
                  onClick={() => {
                    InputFile.current.click();
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloudUpload
                    style={{ width: 30, height: 30, color: "primary" }}
                  />
                </CardActionArea>
              )}
            </div>
          </div>
          <Input
            disabled={Series ? true : false}
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
            }}
            value={Search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={"Search"}
          />

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <EpisodesTable
                canDelete={canDelete}
                canEdit={canEdit}
                Series={Series}
                Search={Search}
                onEdit={(row) => {
                  setEpisodeToEdit(row);
                  setEditPopup(true);
                }}
                RefreshComp={RefreshComp || RefreshCompOut}
                onDeletedRefresh={() => {
                  setonDeletedRefresh(!onDeletedRefresh);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeriesEpisodes;
