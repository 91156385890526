import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CardActionArea, Checkbox } from "@mui/material";

export default function CheckBox(props) {
  const {
    onChecked,
    isChecked = false,
    Name,
    style = {},
    isDisabled = false,
    useStyle = false,
  } = props;

  const [CheckedState, setCheckedState] = React.useState(false);
  React.useEffect(() => {
    setCheckedState(isChecked);
  }, [isChecked]);

  const CheckRef = React.useRef(null);

  // console.log(isChecked);
  return (
    <CardActionArea
      disabled={isDisabled}
      onClick={() => {
        setCheckedState(!CheckedState);
        onChecked?.(!CheckedState, Name);
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 10,
        width: "auto",
        height: "auto",
        padding: 5,
        borderRadius: 10,
        ...(useStyle ? {} : style),
      }}
    >
      <Checkbox
        ref={CheckRef}
        disabled={isDisabled}
        style={{ width: 20, height: 20 }}
        checked={CheckedState}
        onChange={(e) => {
          // setCheckedState(e.target.checked);
          // onChecked?.(e.target.checked, Name);
        }}
      />
      <h3 style={{ marginLeft: 5 }}>{Name}</h3>
    </CardActionArea>
  );
}
