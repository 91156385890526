//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import {
  CreateSerial,
  CreateSerialsMass,
  GetCodesManual,
  GetPeriods,
} from "../Assets/HttpBank";

const UploadSerial = (prop) => {
  const { Refresh, Close } = prop;
  const navigate = useNavigate();
  const [FileValue, setFile] = useState({});
  const [Period, setPeriod] = useState(0);
  const [Points, setPoints] = useState(0);
  const [ReceiverType, setReceiverType] = useState("");
  const [Code, setCode] = useState("");

  const [Periods, setPeriods] = useState([]);

  const [isGenerate, setIsGenerate] = useState(false);

  const [Codes, setCodes] = useState([]);

  useEffect(() => {
    (async () => {
      const Codes = await GetCodesManual({});
      setCodes(Codes?.Codes ? Codes?.Codes : []);
      if (Codes?.Codes[0]) {
        setCode(Codes?.Codes[0].Code);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Periods = await GetPeriods();
      setPeriods(Periods?.Periods ? Periods?.Periods : []);
      if (Periods?.Periods[0]) {
        setPeriod(Periods?.Periods[0].Period);
        setPoints(Periods?.Periods[0].Points);
      }
    })();
  }, []);

  const [IsCopied, setIsCopied] = useState(false);
  const [CopyData, setCopyData] = useState("");
  useEffect(() => {
    // Listen to control v or command and v and get the text from the input
    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      setCopyData(text?.trim?.());
      setIsCopied(true);
      // setFile(text.split("\n"));
    };
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  const readFile = async () => {
    const file = FileValue ? FileValue : undefined;
    if (!file || (CopyData?.length <= 0 && !file))
      return prop?.Message?.({
        Message: "Please choose a file first.",
        Type: "error",
      });
    prop?.Message?.({ Message: "Creating serial...", Type: "info" });
    if (IsCopied) {
      const Serials = [];
      const text = CopyData;
      const ArrayText = text?.split("\n");
      for (let index = 0; index < ArrayText.length; index++) {
        const element = ArrayText[index];
        const line = element;
        const Serial = {
          Serial: line,
          Code: Code,
          Period: Period,
          ReceiverType: ReceiverType,
          CreatedAt: new Date(),
        };
        Serials.push(Serial);
      }

      const { Valid, InsertedSerialsCount, InsertedErrors } =
        await CreateSerialsMass({
          Serials,
        });
      console.log(Valid, InsertedSerialsCount, InsertedErrors);

      if (Valid) {
        // alert(
        //   `Serials Created, ${InsertedSerialsCount} Serials Inserted, ${InsertedErrors} Errors`
        // );
        prop?.Message?.({
          Message: `Serials Created, ${InsertedSerialsCount} Serials Inserted, ${InsertedErrors} Errors`,
          Type: InsertedErrors >= 1 ? "warning" : "success",
        });
      } else {
        // alert( "Error Creating Serials, Check the file format" );

        prop?.Message?.({
          Message: "Error Creating Serials, Check the file format",
          Type: "error",
        });
      }
      Refresh?.();
      Close?.();
    } else {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async (e) => {
        const Serials = [];
        const text = reader.result;
        const ArrayText = text?.split("\n");
        for (let index = 0; index < ArrayText.length; index++) {
          const element = ArrayText[index];
          const line = element;
          const Serial = {
            Serial: line?.trim?.(),
            Code: Code,
            Period: Period,
            ReceiverType: ReceiverType,
            CreatedAt: new Date(),
          };
          Serials.push(Serial);
        }

        const { Valid, InsertedSerialsCount, InsertedErrors } =
          await CreateSerialsMass({
            Serials,
          });
        console.log(Valid, InsertedSerialsCount, InsertedErrors);

        if (Valid) {
          // alert(
          //   `Serials Created, ${InsertedSerialsCount} Serials Inserted, ${InsertedErrors} Errors`
          // );
          prop?.Message?.({
            Message: `Serials Created, ${InsertedSerialsCount} Serials Inserted, ${InsertedErrors} Errors`,
            Type: "success",
          });
        } else {
          // alert( "Error Creating Serials, Check the file format" );

          prop?.Message?.({
            Message: "Error Creating Serials, Check the file format",
            Type: "error",
          });
        }
        Refresh?.();
        Close?.();
      };
    }
    return "";
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add Serial or Mac Address.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      {IsCopied && (
        <InputLabel style={{ marginTop: 30, marginLeft: 10 }}>
          Data Copied
        </InputLabel>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginLeft: 10,
          marginTop: 10,
        }}
      >
        <Input
          type={"file"}
          style={{
            width: "90%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          onChange={(e) => {
            setCopyData("");
            setIsCopied(false);
            setFile(e.target.files[0]);
            // const Data = await readFile(e);
            // e.target.value = Data;
          }}
        />
      </div>

      {IsCopied && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          <Input
            type={"textarea"}
            multiline={true}
            value={CopyData}
            style={{
              width: "90%",
              height: "auto",
              minHeight: 50,
              maxHeight: 150,
              overflow: "scroll",
              lineBreak: "normal",
              backgroundColor: "#e6e6e6",
              padding: 10,
              borderRadius: 10,
            }}
            disableUnderline={true}
            onChange={(e) => {
              setCopyData(e.target.value);
            }}
          />
        </div>
      )}

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">The code?</InputLabel>
        <Select
          onChange={(e) => {
            setCode(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Code}
          label="Age"
          // onChange={handleChange}
        >
          {Codes.map((Item) => {
            return <MenuItem value={Item?.Code}>{Item?.Code}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Period In Days?</InputLabel>
        <Select
          onChange={(e) => {
            setPeriod(e.target.value);
            setPoints(
              Periods.filter((x) => x.Period == e.target.value)[0]?.Points
            );
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Period}
          label="Age"
          // onChange={handleChange}
        >
          {Periods.map((Period) => {
            return (
              <MenuItem value={Period?.Period}>
                {Period?.Period} Days - {Period?.Points <= 0 ? "Demo" : "Paid"}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          marginLeft: 10,
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <Input
          style={{
            width: "100%",
            height: 50,
            backgroundColor: "#e6e6e6",
            padding: 10,
            borderRadius: 10,
          }}
          disableUnderline={true}
          placeholder={"Receiver Type"}
          value={ReceiverType}
          onChange={(e) => {
            setReceiverType(e.target.value);
          }}
        />
      </div>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          readFile();
        }}
      >
        <h5>Upload</h5>
      </Button>
    </Card>
  );
};

export default UploadSerial;
