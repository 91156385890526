//New react functional component
import { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import {
  CreateCodes,
  GetCategoriesByType,
  GetPeriods,
  GetSettings,
  GetSubCategoriesByCategory,
  GetSubCategoriesLivesCount,
  UploadLives,
} from "../Assets/HttpBank";

import xml2js from "xml2js";
let parser = new xml2js.Parser();

const UploadLivesForm = (prop) => {
  const { canManual, canGenerate } = prop;

  const navigate = useNavigate();
  const [SubCategory, setSubCategory] = useState({});
  const [SubCategories, setSubCategories] = useState([]);

  const [Categories, setCategories] = useState([]);
  const [Category, setCategory] = useState({});

  const [File, setFile] = useState(null);

  useEffect(() => {
    (async () => {
      const Data = await GetSubCategoriesLivesCount({});
      const SubCategories = Data?.SubCategories;
      setSubCategories([...SubCategories]);
      if (SubCategories[0]) {
        setSubCategory(SubCategories[0]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const Data = await GetSubCategoriesByCategory({
        CategoryID: `${Category?._id ? Category?._id : Category}`,
      });
      const SubCategories = Data?.SubCategories ? Data?.SubCategories : [];
      setSubCategories([...SubCategories]);
      if (SubCategories[0]) {
        setSubCategory(SubCategories[0]);
      }
    })();
  }, [Category]);

  useEffect(() => {
    (async () => {
      const Data = await GetCategoriesByType("Lives");
      const Categories = Data?.Categories ? Data?.Categories : [];
      setCategories([...Categories]);
      if (Categories[0]) {
        setCategory(Categories[0]);
      }
    })();
  }, []);

  const readFile = async (e) => {
    prop?.Message?.({ Message: "Preparing the data...", Type: "info" });

    const file = e.target.files[0];
    //file is a file with lines separated by \n read as text
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = async (e) => {
      const text = reader.result;

      parser.parseString(text, function (err, Lives) {
        if (err) return alert("Please check the file format.");
        const AllLIVES = Lives?.root?.movie;
        console.warn(AllLIVES);
        const LivesToUpload = [];
        for (let index = 0; index < AllLIVES?.length; index++) {
          const { name: Name, link: Url } = AllLIVES[index]?.["$"];

          const LiveElement = {
            SubCategory: SubCategory?._id,
            Category: Category?._id,
            Name,
            Url,
          };
          LivesToUpload.push(LiveElement);
        }

        if (LivesToUpload.length <= 0)
          return alert("Please check the file contains episodes.");
        (async () => {
          console.log(LivesToUpload);
          const { Valid, InsertedCount, InsertedErrors } = await UploadLives({
            Data: LivesToUpload,
          });

          if (Valid) {
            prop?.Message?.({
              Message: `Lives Created, ${InsertedCount} Lives Inserted, ${InsertedErrors} Errors`,
              Type: "success",
            });
          } else {
            prop?.Message?.({
              Message: "Error Creating Lives, Check the file format",
              Type: "error",
            });
          }

          prop?.Refresh?.();
          prop?.Close?.();
        })();
      });
    };
    return "";
  };

  const InputFile = createRef();

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "30%",
        // height: "70%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <input
        ref={InputFile}
        type="file"
        style={{ display: "none" }}
        onChange={async (e) => {
          const Data = await readFile(e);
          e.target.value = Data;
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Upload Lives Form.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 30,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Category?</InputLabel>
        <Select
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={Category}
          label="Age"
          // onChange={handleChange}
        >
          {Categories.map((Category) => {
            return <MenuItem value={Category}>{Category?.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <FormControl
        style={{
          width: "90%",
          height: 60,
          //   backgroundColor: "lightgrey",
          padding: 10,
          marginTop: 20,
          borderRadius: 10,
        }}
      >
        <InputLabel id="demo-simple-select-label">Sub Category?</InputLabel>
        <Select
          onChange={(e) => {
            setSubCategory(e.target.value);
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={SubCategory}
          label="Age"
          // onChange={handleChange}
        >
          {SubCategories.map((SubCategory) => {
            return <MenuItem value={SubCategory}>{SubCategory?.Name}</MenuItem>;
          })}
        </Select>
      </FormControl>

      <div
        style={{
          width: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          marginLeft: 10,
          marginTop: 30,
        }}
      ></div>

      <h5 style={{ marginTop: 20, color: "red", fontWeight: "bolder" }}>
        Please keep in mind, That the file should always be a valid xml file.
      </h5>

      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <Button
        style={{
          width: "90%",
          marginLeft: 10,
          marginTop: 30,
          textAlign: "start",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: 10,
        }}
        onClick={() => {
          InputFile.current.click();
        }}
      >
        <h5>Choose file and upload.</h5>
      </Button>
    </Card>
  );
};

export default UploadLivesForm;
