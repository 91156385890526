//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  CardActionArea,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";

import {
  CreateSeries,
  CreateUser,
  GetSubCategoriesSeriesCount,
} from "../Assets/HttpBank";
import AllPrivileges from "../Assets/AllPrivileges";
import CheckBox from "./Checkbox";

const AddUser = (prop) => {
  const navigate = useNavigate();
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [Balance, setBalance] = useState();

  const [Privileges, setPrivileges] = useState({ ...AllPrivileges });

  const Create = async () => {
    prop?.Message?.({ Message: "Creating User...", Type: "info" });
    const CreatedSeries = await CreateUser({
      Username,
      Password,
      Balance,
      Privileges,
    });

    if (!CreatedSeries?.Valid) {
      prop?.Message?.({
        Message: "Couldn't create the User.",
        Type: "error",
      });
      return;
    }

    prop?.Message?.({
      Message: `User created!`,
      Type: "success",
    });

    setPrivileges({ ...AllPrivileges });
    prop?.Refresh?.();
    prop?.Close?.();

    //Show snackbar with the message
  };

  return (
    <Card
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        width: "80%",
        height: "90%",
        maxHeight: "90%",
        zIndex: 2,
        padding: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ fontSize: 18, color: "grey", fontWeight: "bolder" }}>
          Add User.
        </h2>

        <CardActionArea
          onClick={() => {
            prop?.Close?.();
          }}
          style={{
            borderRadius: 20,
            width: 30,
            height: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Cancel style={{ width: 20, height: 20, color: "black" }} />
        </CardActionArea>
      </div>
      <div style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          // overflow: "scroll",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "40%" }}>
          <div style={{ position: "absolute", width: "40%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: 10,
                marginTop: 30,
              }}
            >
              <Input
                autoComplete="new-password"
                type={"text"}
                value={Username}
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: "#e6e6e6",
                  padding: 10,
                  borderRadius: 10,
                }}
                disableUnderline={true}
                placeholder={"Username"}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>

            <Input
              autoComplete="new-password"
              type="password"
              style={{
                width: "100%",
                height: 50,
                backgroundColor: "#e6e6e6",
                padding: 10,
                marginLeft: 10,
                marginTop: 30,
                borderRadius: 10,
              }}
              disableUnderline={true}
              value={Password}
              placeholder={"Password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <Input
              type="number"
              style={{
                width: "100%",
                height: 50,
                backgroundColor: "#e6e6e6",
                padding: 10,
                marginLeft: 10,
                marginTop: 30,
                borderRadius: 10,
                marginBottom: 30,
              }}
              disableUnderline={true}
              value={Balance}
              placeholder={"Balance (Points)"}
              onChange={(e) => {
                setBalance(e.target.value);
              }}
            />

            <div
              style={{ width: "95%", height: 1, backgroundColor: "lightgrey" }}
            />

            <Button
              style={{
                width: "90%",
                marginLeft: 10,
                marginTop: 30,
                textAlign: "start",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: 10,
              }}
              onClick={() => {
                Create();
              }}
            >
              <h5>Create</h5>
            </Button>
          </div>
        </div>

        <div
          style={{
            width: "57%",
            display: "flex",
            flexDirection: "column",
            maxHeight: "90%",
            height: "90%",
            // overflow: "scroll",
            position: "absolute",
            top: "10%",
            right: "-20px",
            bottom: "-20px",
            // right: "-20px",
            overflow: "scroll",
          }}
        >
          <div>
            <h3>Navigation</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Navigation?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Navigation?.map?.(
                        (item, index) => {
                          if (item.Name === Name) {
                            item.permitted = Checked;
                          }
                          return item;
                        }
                      );

                      Object.Navigation = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Delete</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Delete?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Delete?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Delete = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Edit</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Edit?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Edit?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Edit = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Upload</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Upload?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Upload?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Upload = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Generate</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Generate?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Generate?.map?.(
                        (item, index) => {
                          if (item.Name === Name) {
                            item.permitted = Checked;
                          }
                          return item;
                        }
                      );

                      Object.Generate = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Manual</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Manual?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Manual?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Manual = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Export</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Export?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Export?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Export = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Create</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Create?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Create?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Create = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <h3>Status</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Privileges?.Status?.map?.((item, index) => {
                return (
                  <CheckBox
                    Name={item.Name}
                    // style={{ width: 20, height: 20 }}
                    isChecked={item.permitted}
                    onChecked={(Checked, Name) => {
                      const Object = { ...Privileges };
                      const NewArray = Object?.Status?.map?.((item, index) => {
                        if (item.Name === Name) {
                          item.permitted = Checked;
                        }
                        return item;
                      });

                      Object.Status = [...NewArray];

                      setPrivileges({ ...Object });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AddUser;
