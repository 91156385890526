//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, MacAddress, Host, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import { GetPrivileges, ValidateToken } from "../Assets/HttpBank";
import { Add, CloudUpload, Details } from "@mui/icons-material";
import LivesTable from "../Components/LivesTable";
import EditLives from "../Components/EditLives";
import EditServer from "../Components/EditServer";

import UploadLivesForm from "../Components/UploadLivesForm";
import socketio from "socket.io-client";
import ServersTable from "../Components/ServersTable";
import AddServer from "../Components/AddServer";
import ServerStatus from "../Components/ServerStatus";

const Servers = (prop) => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/Servers");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [LivesToEdit, setLivesToEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const [canUpload, setcanUpload] = useState(false);

  const [canStatus, setcanStatus] = useState(false);

  useEffect(() => {
    (async () => {
      const { Privileges, isAdmin } = await GetPrivileges();

      console.log(Privileges);
      if (isAdmin) {
        setCanAdd(true);
        setCanEdit(true);
        setCanDelete(true);
        setcanUpload(true);
        setcanStatus(true);
      } else {
        const GetCreate = Privileges?.Create?.filter?.(
          (item) => item.Name === "Servers"
        );

        setCanAdd(GetCreate[0]?.permitted);

        const GetEdit = Privileges?.Edit?.filter?.(
          (item) => item.Name === "Servers"
        );

        setCanEdit(GetEdit[0]?.permitted);

        const GetDelete = Privileges?.Delete?.filter?.(
          (item) => item.Name === "Servers"
        );

        setCanDelete(GetDelete[0]?.permitted);

        const GetUpload = Privileges?.Upload?.filter?.(
          (item) => item.Name === "Servers"
        );

        setcanUpload(GetUpload[0]?.permitted);

        const GetStatus = Privileges?.Status?.filter?.(
          (item) => item.Name === "Servers"
        );

        setcanStatus(GetStatus[0]?.permitted);
      }
    })();
  }, []);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  const [Search, setSearch] = useState("");

  const [ShowUploadForm, setShowUploadForm] = useState(false);

  const [WatchingNow, setWatchingNow] = useState(0);

  const [Servers, setServers] = useState({});
  const [ServersData, setServersData] = useState({});

  useEffect(() => {
    const socket = socketio(
      `http://realtime.${Host.replace("http://", "").replace("https://", "")}`
    ).connect();

    socket.on("watchingNow", (data) => {
      setWatchingNow(data > 0 ? data : 0);
    });

    socket.emit("getWatchingNow");

    socket.emit("getServersNow", "", (Data) => {
      setServers(Data);
    });

    const intervalGetServers = setInterval(() => {
      socket.emit("getServersNow", "", (Data) => {
        setServers(Data);
      });
    }, 1000);

    return () => {
      clearInterval(intervalGetServers);
    };
  }, []);

  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      {Popup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddServer
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
            data={(data) => {
              console.log(data);
              if (Array.isArray(data)) setServersData(data);
            }}
          />
        </div>
      )}

      {EditPopup && (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginRight: "20%",
            height: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EditServer
            ServerToEdit={LivesToEdit}
            Refresh={() => {
              setRefreshComp(!RefreshComp);
            }}
            Close={() => {
              setEditPopup(false);
            }}
            Message={(Message) => {
              setMessage(Message);
            }}
            data={(data) => {
              console.log(data);
              if (Array.isArray(data)) setServersData(data);
            }}
          />
        </div>
      )}

      {Popup || EditPopup || ShowUploadForm ? (
        <div
          onClick={() => {
            setPopup(false);
            setEditPopup(false);
            setShowUploadForm(false);
          }}
          style={{
            width: "100%",
            height: "100%",
            marginRight: "20%",
            backgroundColor: "rgb(0,0,0,0.8)",
            position: "absolute",
            zIndex: 1,
          }}
        />
      ) : null}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        {/* <NavigationBar /> */}
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <CardActionArea
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 15,
                width: "auto",
                padding: 10,
                height: "auto",
                borderRadius: 10,
              }}
            >
              <h2
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 20,
                }}
              >
                Servers ~
              </h2>
              <h2
                style={{
                  color: "red",
                  marginLeft: 5,
                  transition: "color 0.5s ease-in-out",
                  animation: `${WatchingNow > 0 ? "blink" : "none"
                    } 1s infinite`,
                }}
              >
                {WatchingNow} Connections
              </h2>
            </CardActionArea>
            {/* <h2 style={{ color: "red", fontSize: 15 }}>
              Offline: {OfflineCount}
            </h2>
            <h2 style={{ color: "green", fontSize: 15 }}>
              Online: {OnlineCount}
            </h2> */}

            <div
              style={{
                display: "flex",
                width: 100,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {canAdd && (
                <CardActionArea
                  onClick={() => {
                    setPopup(true);
                  }}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Add style={{ width: 30, height: 30, color: "primary" }} />
                </CardActionArea>
              )}
            </div>
          </div>

          <div style={{ width: "100%", height: "85%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >

              <ServersTable
                canDelete={canDelete}
                canEdit={canEdit}
                canStatus={canStatus}
                Search={Search}
                Servers={Servers}
                ServersData={ServersData}
                onEdit={(row) => {
                  setLivesToEdit(row);
                  setEditPopup(true);
                }}
                onCounts={(OfflineCount, OnlineCount) => { }}
                RefreshComp={RefreshComp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servers;
