//Create Navigation for the react router
import { createRef, useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  ClickAwayListener,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate, useParams } from "react-router-dom";
import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import { GetToken, SaveCurrentPage, SaveToken } from "../Assets/Storage";
import {
  GetMediaViewsByTypeID,
  GetPrivileges,
  ValidateToken,
} from "../Assets/HttpBank";

import socketio from "socket.io-client";

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0
    ? minutes > 0
      ? `${hours}h ${minutes}m`
      : `${hours}h`
    : `${minutes}m`;
}

const LiveViews = () => {
  //Get Param
  const { id: ID, type: Type, realTime: IsRealTime, page: pageName } = useParams();

  const [ViewsByCountry, setViewsByCountry] = useState([]);
  const [ViewsByDevice, setViewsByDevice] = useState([]);
  const [SelectedCountry, setSelectedCountry] = useState([]);
  const [OpenPopup, setOpenPopup] = useState(false);
  useEffect(() => {
    (async () => {
      const { Views, Valid } = await GetMediaViewsByTypeID(
        ID,
        Type,
        IsRealTime,
        pageName
      );

      if (!Valid) return;

      // Views Is an array of objects that includes a key called country, Merge each different of them into one array
      const ViewsByCountry = Views.reduce((acc, curr) => {
        const country = curr.Country;
        if (!acc[country]) {
          acc[country] = {};
          acc[country].array = [];
        }
        console.log(curr);
        acc[country].Views = acc[country]?.Views ? acc[country]?.Views + 1 : 1;

        acc[country].array.push(curr);
        return acc;
      }, {});

      const Array = [];
      const ArrayOfDevices = [];

      for (const key in ViewsByCountry) {
        Array.push({
          Country: key,
          Views: ViewsByCountry[key].Views,
        });
        ArrayOfDevices.push({
          Country: key,
          Array: ViewsByCountry[key].array,
        });
      }

      setViewsByDevice(ArrayOfDevices);
      setViewsByCountry([...Array]);
    })();
  }, []);

  useEffect(() => {
    console.log(SelectedCountry);
  }, [SelectedCountry]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >

      {OpenPopup && (
        <CardActionArea
          onClick={() => {
            setOpenPopup(false);
          }}
          style={{
            display: "flex",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            zIndex: 2,
          }}
        />
      )}

      {OpenPopup && (
        <Card
          style={{
            position: "absolute",
            display: "flex",
            left: "30%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "50%",
            height: "70%",
            maxHeight: "70%",
            overflow: "scroll",
            zIndex: 3,
            // padding: 20,
          }}
        >
          <CardActionArea
            disabled={true}
            style={{
              width: "50%",
              height: 100,
              background: "#2D374B",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "row",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: 15,
              fontWeight: "bolder",
              color: "white",
              position: "fixed",
              zIndex: 1,
            }}
          >
            <text style={{ width: "50%" }}>{"Serial Number"}</text>
          </CardActionArea>

          <div
            style={{
              // padding: 20,
              width: "100%",
              height: "100%",
              marginTop: 100,
              zIndex: 10,
            }}
          >
            {SelectedCountry.map((item, index) => {
              return (
                <CardActionArea
                  onClick={() => {
                    window.location = `/Clients?search=${item.Serial ? item.Serial : item?.SerialNumber
                      }`;
                  }}
                  key={index}
                  style={{
                    width: "100%",
                    height: 70,
                    background: index % 2 === 0 ? "white" : "#FFFFE0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    padding: 20,
                  }}
                >
                  <text
                    style={{
                      width: "50%",
                      fontWeight: "bold",
                      color: "black",
                      fontSize: 17,
                    }}
                  >
                    {item.Serial ? item.Serial : item?.SerialNumber}{" "}
                    <text style={{ color: "green" }}>
                      {item?.StartedAt
                        ? toHoursAndMinutes(
                          Math.round(
                            (new Date().getTime() -
                              new Date(item?.StartedAt)?.getTime?.()) /
                            60000
                          )
                        )
                        : ""}
                    </text>
                  </text>
                </CardActionArea>
              );
            })}
          </div>
        </Card>
      )}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Countries</h2>
          </div>

          <div
            style={{
              marginTop: 50,
              height: "auto",
              maxHeight: "80%",
              width: "100%",
              overflow: "scroll",
              // display: "flex",
              paddingBottom: 150,
              background: "white",
            }}
          >
            <CardActionArea
              disabled={true}
              style={{
                width: "72%",
                height: 100,
                background: "#2D374B",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 15,
                fontWeight: "bolder",
                color: "white",
                position: "fixed",
                zIndex: 1,
              }}
            >
              <text style={{ width: "50%" }}>{"Country"}</text>
              <text style={{ width: "50%" }}>{"Views"}</text>
            </CardActionArea>

            <CardActionArea
              disabled={true}
              style={{
                width: "72%",
                height: 100,
                background: "#2D374B",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: 15,
                fontWeight: "bolder",
                color: "white",
              }}
            />

            {ViewsByCountry.map(({ Country, Views }, index) => {
              //Every 2 items, create a new color
              const backgroundColor = index % 2 === 0 ? "white" : "#ffbb69";

              return (
                <CardActionArea
                  // disabled={true}
                  onClick={() => {
                    const Array = ViewsByDevice.filter((Item) => {
                      return Item?.Country === Country;
                    })[0]?.Array;

                    setSelectedCountry(Array ? Array : []);
                    setOpenPopup(true);
                  }}
                  style={{
                    width: "100%",
                    height: 50,
                    background: backgroundColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 15,
                    fontWeight: "bolder",
                  }}
                  key={index}
                >
                  <text
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    {Country}
                  </text>
                  <text style={{ width: "50%" }}>{Views}</text>
                </CardActionArea>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveViews;
