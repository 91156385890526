//New react functional component
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  DeleteClient,
  GetClientAndCount,
  GetClientAndCountSearch,
  GetSettings,
} from "../Assets/HttpBank";

import { Delete, Edit } from "@mui/icons-material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ScrollDialog from "./Confirm";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

const Row = (props) => {
  const { row, onDelete, onEdit, canDelete, canEdit, RefreshClientsData } =
    props;
  const [open, setOpen] = useState(false);

  const [Show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, [RefreshClientsData]);

  return (
    <Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          opacity: !Show ? 0 : 1,
          transition: "all .6s",
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.Code}
        </TableCell>
        <TableCell align="left">
          {row?.Period}
          {" ~ "}
          {row?.Points > 0 ? (
            <text style={{ color: "green" }}>Paid</text>
          ) : (
            <text style={{ color: "red" }}>Demo</text>
          )}
        </TableCell>
        <TableCell align="left">{row?.SerialNumber}</TableCell>
        <TableCell align="left">{row?.CountryCode}</TableCell>
        <TableCell align="left">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {canEdit && (
              <CardActionArea
                onClick={() => {
                  onEdit?.(row._id);
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Edit style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}

            {canDelete && (
              <CardActionArea
                onClick={() => {
                  onDelete?.();
                }}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Delete style={{ width: 20, height: 20, color: "primary" }} />
              </CardActionArea>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Extra
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Created At</TableCell>
                    <TableCell>Expire At</TableCell>
                    <TableCell>Subscription Type</TableCell>
                    <TableCell align="right">Blocked</TableCell>
                    <TableCell align="right">Mac Address</TableCell>
                    <TableCell align="right">IP</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {row?.history?.map?.((historyRow) => ( */}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {new Date(row?.CreatedAt).toDateString()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {new Date(row?.ExpireAt).toISOString().split("T")[0]}
                    </TableCell>

                    <TableCell>{row?.SubscriptionType}</TableCell>
                    <TableCell align="right">
                      {row?.Blocked ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="right">{row.MacAddress}</TableCell>
                    <TableCell align="right">{row.IP}</TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 3.99),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
  createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
  createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
];

const ClientsTable = (prop) => {
  const { RefreshComp, onEdit, onDelete, Search, canDelete, canEdit } = prop;
  const navigate = useNavigate();
  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);

  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [Clients, setClients] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshClientsData, setRefreshClientsData] = useState(false);
  const RefreshCodes = async () => {
    if (Search.length > 0 && Skip === 0) return;

    setRefreshClientsData(false);
    if (NumberOfRows >= 1) {
      const Data = await GetClientAndCount(Skip, NumberOfRows);
      const { Count, Clients, Valid } = Data ? Data : {};
      if (!Valid) return;
      setClients(Clients);
      setCount(Count);
      setRefreshClientsData(true);
    }
  };

  useEffect(() => {
    RefreshCodes();
  }, [Skip, NumberOfRows, RefreshComp]);

  useEffect(() => {
    setRefreshClientsData(false);
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetClientAndCountSearch(Skip, NumberOfRows, Search);
        if (!Data) {
          return RefreshCodes();
        }

        const { Count, Clients, Valid } = Data ? Data : {};
        if (!Valid) return;
        setClients(Clients);
        setCount(Count);
        setRefreshClientsData(true);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, Search]);

  const [Open, setOpen] = useState(false);
  const [IdToAction, setIdToAction] = useState("");

  return (
    <TableContainer component={Paper}>
      {Open && (
        <ScrollDialog
          Confirm={async () => {
            const DeleteCode = await DeleteClient({ ClientID: IdToAction });
            RefreshCodes();
            setOpen(false);
            onDelete?.(IdToAction);
          }}
          Cancel={() => {
            setOpen(false);
          }}
          Title={"Are you sure?"}
          Message={
            "You are removing an activation code that may have been used by an individuals. Do you want to continue?"
          }
        />
      )}
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Value</TableCell>
            <TableCell align="left">Period In Days</TableCell>
            <TableCell align="left">Serial Number</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: Clients.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {Clients.map((row, index) => (
            <Row
              RefreshClientsData={RefreshClientsData}
              canDelete={canDelete}
              canEdit={canEdit}
              onDelete={() => {
                setOpen(true);
                setIdToAction(row._id);
              }}
              onEdit={() => {
                onEdit?.(row);
              }}
              key={index}
              row={row}
            />
          ))}
        </TableBody>

        <div
          style={{
            width: "100%",
            display: "flex",

            // alignItems: "center",
            // justifyContent: "center",

            padding: 10,
          }}
        >
          <TablePagination
            component="div"
            count={Count}
            page={Page}
            // labelDisplayedRows={({ from, to, count }) => {
            //   return `${from}-${to} of ${count}`;
            // }}
            onPageChange={(c, page) => {
              setPage(page);
              setSkip(page * NumberOfRows);
              //   alert(page);
            }}
            rowsPerPage={NumberOfRows}
            rowsPerPageOptions={[6]}
          />
        </div>
      </Table>
    </TableContainer>
  );
};

export default ClientsTable;
