import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import SignIn from "./Pages/SignIn";
import { render } from "react-dom";
import Codes from "./Pages/Codes";
import SerialsAndMacs from "./Pages/SerialsAndMacs";
import Clients from "./Pages/Clients";
import Users from "./Pages/Users";
import Categories from "./Pages/Categories";
import Series from "./Pages/Series";
import SeriesEpisodes from "./Pages/SeriesEpisodes";
import SubCategories from "./Pages/SubCategories";
import Periods from "./Pages/Periods";
import Countries from "./Pages/Countries";
import { GetToken } from "./Assets/Storage";
import NavigationBar from "./Components/NavigationBar";
import Movies from "./Pages/Movies";
import Lives from "./Pages/Lives";
import Settings from "./Pages/Settings";
import LiveViews from "./Pages/LiveViews";
import Servers from "./Pages/Servers";

const rootElement = document.getElementById("root");

render(
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      width: "100wh",
      height: "100vh",
    }}
  >
    <BrowserRouter>
      {GetToken()?.length >= 1 && <NavigationBar />}
      <Routes>
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/" element={<Home />} />
        <Route path="/Categories" element={<Categories />} />
        <Route path="/SubCategories" element={<SubCategories />} />
        <Route path="/Periods" element={<Periods />} />
        <Route path="/Countries" element={<Countries />} />
        <Route path="/Series" element={<Series />} />
        <Route path="/Movies" element={<Movies />} />
        <Route path="/Lives" element={<Lives />} />
        <Route path="/Servers" element={<Servers />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/SeriesEpisodes" element={<SeriesEpisodes />} />
        <Route path="/Codes" element={<Codes />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/SerialsAndMacs" element={<SerialsAndMacs />} />
        <Route path="/LiveViews/:id/:type/:realTime" element={<LiveViews />} />
        <Route path="/LiveViews/:id/:type/:realTime/:page" element={<LiveViews />} />
      </Routes>
    </BrowserRouter>
  </div>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
