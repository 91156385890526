//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  GetCountriesAndCount,
  GetCountriesAndCountSearch,
  GetSeriesAndCount,
  GetSeriesAndCountSearch,
  GetSettings,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import CountryItem from "./CountryItem";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";

function createData(name, index) {
  return { name, index };
}

const CountriesTable = (prop) => {
  const { RefreshComp, onEdit, Search, canDelete } = prop;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);
  const [Countries, setCountries] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const RefreshSeries = async () => {
    if (NumberOfRows >= 1) {
      const Data = await GetCountriesAndCount(Skip, NumberOfRows);
      const { Count, Countries, Valid } = Data ? Data : {};
      if (!Valid) return;
      setCountries(Countries);
      setCount(Count);
    }
  };

  useEffect(() => {
    if (Search?.length <= 0) {
      RefreshSeries();
    }
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetCountriesAndCountSearch(
          Skip,
          NumberOfRows,
          Search
        );
        if (!Data) {
          return RefreshSeries();
        }

        const { Count, Countries, Valid } = Data ? Data : {};
        if (!Valid) return;
        setCountries(Countries);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "100%" }}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Blocked</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: Countries.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {Countries.map((row, index) => {
            return (
              <CountryItem
                canDelete={canDelete}
                key={index}
                row={row}
                RefreshCompInside={() => {
                  setRefreshCompInside(!RefreshCompInside);
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>

      <div
        style={{
          width: "100%",
          display: "flex",

          alignItems: "center",
          justifyContent: "center",

          padding: 10,
        }}
      >
        <TablePagination
          component="div"
          count={Count}
          page={Page}
          onPageChange={(c, page) => {
            setPage(page);
            setSkip(page * NumberOfRows);
            //   alert(page);
          }}
          rowsPerPage={NumberOfRows}
          rowsPerPageOptions={[6]}
        />
      </div>
    </TableContainer>
  );
};

export default CountriesTable;
