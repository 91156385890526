//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { DeleteCategory } from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const CategoriesItem = (prop) => {
  const { row, RefreshCompInside, onEdit, canDelete, canEdit } = prop;

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);
  const [position, setPosition] = useState("relative");

  return (
    <TableRow>
      <TableCell
        style={{
          minWidth: 120,
          height: 40,
        }}
      >
        <img
          onMouseEnter={() => {
            setTimeout(() => {
              setPosition("absolute");
            }, 100);
          }}
          onMouseOut={() => {
            setTimeout(() => {
              setPosition("relative");
            }, 100);
          }}
          className="Item"
          alt={""}
          src={
            row?.Logo?.includes?.("http")
              ? `${Host}/Admin/ProxyImage?ImageURL=${row.Logo}`
              : row.Logo
          }
          width={34}
          height={34}
          style={{ position: position }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>{row.Name}</TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {new Date(row?.CreatedAt).toDateString()}
      </TableCell>

      <TableCell style={{ minWidth: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {canEdit && (
            <CardActionArea
              onClick={() => {
                onEdit?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Edit style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canDelete && (
            <CardActionArea
              onClick={async () => {
                if (
                  !window.confirm("Are you sure you wish to delete this item?")
                )
                  return;

                const CategoryDeleteAction = await DeleteCategory({
                  CategoryID: row._id,
                });

                if (CategoryDeleteAction.Code === 403) {
                  return alert(
                    "Can't delete this Category, Because there is a Sub linked under this category"
                  );
                }
                RefreshCompInside?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CategoriesItem;
