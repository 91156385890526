//New react functional component
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { DeleteLives, DeleteMovies, DeleteSeries } from "../Assets/HttpBank";
import { Delete, Edit, FolderOpen } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const LivesItem = (prop) => {
  const { row, RefreshCompInside, onEdit, canDelete, canStatus, canEdit } =
    prop;
  const navigate = useNavigate();

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);
  const [position, setPosition] = useState("relative");

  return (
    <TableRow>
      <TableCell style={{ minWidth: 120, height: 40 }}>
        {row?.Picture?.includes?.("http") && (
          <img
            onMouseEnter={() => {
              setTimeout(() => {
                setPosition("absolute");
              }, 100);
            }}
            onMouseOut={() => {
              setTimeout(() => {
                setPosition("relative");
              }, 100);
            }}
            className="Item"
            alt={""}
            src={
              row?.Picture?.includes?.("http")
                ? `${Host}/Admin/ProxyImage?ImageURL=${row.Picture}`
                : row.Picture
            }
            width={34}
            height={34}
            style={{ position: position }}
          />
        )}
        {!row?.Picture?.includes?.("http") && (
          <div
            style={{
              fontSize: 13,
              fontWeight: "bolder",
            }}
          >
            None
          </div>
        )}
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {canStatus && (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                backgroundColor: row?.Status ? "green" : "red",
                marginRight: 10,
              }}
            />
          )}
          {row.Name}
        </div>
      </TableCell>
      <TableCell
        style={{
          minWidth: 120,
        }}
      >
        <div
          onClick={() => {
            window.location = `/LiveViews/${row?._id}/Lives/false`;
          }}
          style={{
            fontSize: 20,
            fontWeight: "bolder",
            cursor: "pointer",
          }}
        >
          {row?.Views}
        </div>
      </TableCell>
      <TableCell
        style={{
          minWidth: 120,
        }}
      >
        <div
          onClick={() => {
            window.location = `/LiveViews/${row?._id}/Lives/true`;
          }}
          style={{
            color: "red",
            fontSize: 20,
            fontWeight: "bolder",
            cursor: "pointer",
          }}
        >
          {row?.LiveViews}
        </div>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {canEdit && (
            <CardActionArea
              onClick={() => {
                onEdit?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Edit style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canDelete && (
            <CardActionArea
              onClick={async () => {
                if (
                  !window.confirm("Are you sure you wish to delete this item?")
                )
                  return;

                const CategoryDeleteAction = await DeleteLives({
                  LivesID: row._id,
                });

                if (CategoryDeleteAction.Code === 403) {
                  return alert("Can't delete this Live, for some reason.");
                }
                RefreshCompInside?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default LivesItem;
