//New react functional component
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import { DeletePeriod, DeleteSubCategory } from "../Assets/HttpBank";
import { Delete, Edit, FolderOpen } from "@mui/icons-material";
import { Host } from "../Assets/Receiver";

function createData(name, index) {
  return { name, index };
}

const PeriodItem = (prop) => {
  const { row, RefreshCompInside, onEdit, canDelete, canEdit } = prop;
  const navigate = useNavigate();

  // const [Logo, setLogo] = useState(
  //   "https://cdnb.artstation.com/p/assets/images/images/023/674/391/large/evgeniy-bogdanov-defaault.jpg?1579980223"
  // );

  // useEffect(() => {
  //   (async () => {
  //     const Image = await ProxyImage(row.Logo);
  //     if (Image) {
  //       console.log(Image);
  //       setLogo(Image);
  //     }
  //   })();
  // }, []);

  return (
    <TableRow>
      <TableCell style={{ minWidth: 120 }}>{row.Period}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{row.Points}</TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {canEdit && (
            <CardActionArea
              onClick={() => {
                onEdit?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Edit style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}

          {canDelete && (
            <CardActionArea
              onClick={async () => {
                if (
                  !window.confirm("Are you sure you wish to delete this item?")
                )
                  return;

                const CategoryDeleteAction = await DeletePeriod({
                  ID: row._id,
                });

                if (CategoryDeleteAction.Code === 403) {
                  return alert("Can't delete this Period, for some reason.");
                }
                RefreshCompInside?.();
              }}
              style={{
                width: 30,
                height: 30,
                borderRadius: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ width: 20, height: 20, color: "primary" }} />
            </CardActionArea>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PeriodItem;
