const Receiver = {
  MacAddress: "00:00:00:00:00:00",
  SerialNumber: "12123124124",
  Code: "1111111111111",
};

export const MacAddress = Receiver.MacAddress;
export const SerialNumber = Receiver.SerialNumber;
export const Code = Receiver.Code;
export const Host = window.location.host.includes("localhost")
  ? "http://batman.bigo.ws"
  : window.location.host.replace("dashboard.", "http://");

console.log(Host);
console.log(window.location.host);
