//New react functional component
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardActionArea,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  TablePagination,
} from "@mui/material";
import {
  GetSubCategoriesCount,
  GetSubCategoriesAndCountSearch,
  GetSettings,
} from "../Assets/HttpBank";
import { Delete, Edit } from "@mui/icons-material";
import CategoriesItem from "./CategoriesItem";
import SeriesItem from "./SeriesItem";
import PeriodItem from "./PeriodItem";

import { GetPeriods } from "../Assets/HttpBank";
import { GetSomeObject, SaveSomeObject } from "../Assets/Storage";
function createData(name, index) {
  return { name, index };
}

const PeriodsTable = (prop) => {
  const { RefreshComp, onEdit, Search, canDelete, canEdit } = prop;

  const navigate = useNavigate();

  const [Page, setPage] = useState(0);
  const [Skip, setSkip] = useState(0);

  const [Settings, setSettings] = useState({});

  const [NumberOfRows, setNumberOfRows] = useState(0);
  useEffect(() => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const data = JSON.parse(Cached);
      setSettings(data);
      setNumberOfRows(data.NumberOfRows);
    }

    setTimeout(async () => {
      const Data = await GetSettings();
      const { Settings, Valid } = Data ? Data : {};
      SaveSomeObject("GetSettings", Settings ? Settings : {});

      setSettings(Settings ? Settings : {});
      setNumberOfRows(Settings ? Settings.NumberOfRows : 6);
    }, 100);
  }, []);

  const [Periods, setPeriods] = useState([]);
  const [Count, setCount] = useState(0);

  const [RefreshCompInside, setRefreshCompInside] = useState(false);

  const RefreshSeries = async () => {
    if (NumberOfRows >= 1) {
      const Data = await GetPeriods({ Skip, Limit: NumberOfRows });
      const { Count, Periods, Valid } = Data ? Data : {};
      if (!Valid) return;
      setPeriods(Periods);
      setCount(Count);
    }
  };

  useEffect(() => {
    if (Search?.length <= 0) {
      RefreshSeries();
    }
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  useEffect(() => {
    (async () => {
      if (NumberOfRows >= 1) {
        const Data = await GetSubCategoriesAndCountSearch(
          Skip,
          NumberOfRows,
          Search
        );
        if (!Data) {
          return RefreshSeries();
        }

        const { Count, Periods, Valid } = Data ? Data : {};
        if (!Valid) return;
        setPeriods(Periods);
        setCount(Count);
      }
    })();
  }, [Skip, NumberOfRows, RefreshComp, RefreshCompInside, Search]);

  return (
    <TableContainer component={Paper} style={{ maxHeight: "70%" }}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Period</TableCell>
            <TableCell>Points (Price)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            opacity: Periods.length <= 0 ? 0 : 1,
            transition: "all .6s",
          }}
        >
          {Periods.map((row, index) => {
            return (
              <PeriodItem
                canDelete={canDelete}
                canEdit={canEdit}
                key={index}
                row={row}
                RefreshCompInside={() => {
                  setRefreshCompInside(!RefreshCompInside);
                }}
                onEdit={() => {
                  onEdit?.(row);
                }}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PeriodsTable;
