//Create Navigation for the react router
import { useEffect, useState } from "react";

import {
  CardActionArea,
  Typography,
  Input,
  Card,
  Snackbar,
  Alert,
} from "@mui/material";

//Import useHistory
import { useNavigate } from "react-router-dom";
import { Code, Host, MacAddress, SerialNumber } from "../Assets/Receiver";

import {
  GetSomeObject,
  GetToken,
  SaveCurrentPage,
  SaveSomeObject,
  SaveToken,
} from "../Assets/Storage";
import {
  ValidateToken,
  GetPoints,
  GetLivesStatus,
  GetSettings,
} from "../Assets/HttpBank";

import EditCategory from "../Components/EditCategory";
import NavigationBar from "../Components/NavigationBar";
import CategoriesTable from "../Components/CategoriesTable";
import { Add, VisibilityOff, Visibility } from "@mui/icons-material";

import AddCategory from "../Components/AddCategory";

import socketio from "socket.io-client";

const Home = () => {
  const navigate = useNavigate();

  const [Points, setPoints] = useState(undefined);
  const [StartedPoints, setStartedPoints] = useState(0);
  const [PointsVisible, setPointsVisible] = useState(false);

  const [Spent, setSpent] = useState(0);
  const [Username, setUsername] = useState("");

  const [AlertOnPoints, setAlertOnPoints] = useState(0);

  const GetSettingsInformation = async () => {
    const Cached = GetSomeObject("GetSettings");
    if (Cached) {
      const Data = JSON.parse(Cached);
      const { Settings, Valid } = Data ? Data : {};
      setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);
    }
    const Data = await GetSettings();
    const { Settings, Valid } = Data ? Data : {};
    SaveSomeObject("GetSettings", Data ? Data : {});
    setAlertOnPoints(Settings ? Settings.AlertOnPoints : 0);
  };

  useEffect(() => {
    GetSettingsInformation();
  }, []);

  const [OfflineCount, setOfflineCount] = useState(0);
  const [OnlineCount, setOnlineCount] = useState(0);
  const [ShowLive, setShowLive] = useState(false);

  useEffect(() => {
    const socket = socketio(
      `http://realtime.${Host.replace("http://", "").replace("https://", "")}`
    ).connect();
    if (ShowLive) {
      socket.on("LiveChanges", (data) => {
        console.log(data);
        setOnlineCount(data?.OnlineCount ? data?.OnlineCount : 0);
        setOfflineCount(data?.OfflineCount ? data?.OfflineCount : 0);
      });
    }
    return () => {
      socket.off("LiveChanges");
      socket.offAny();
      socket.close();
    };
  }, [ShowLive]);

  //color: Points <= AlertOnPoints ? "orange" : "black",
  useEffect(() => {
    (async () => {
      const { Balance, StartedBalance, isAdmin, Username, Spent, Valid } =
        await GetPoints();

      setUsername(Username);
      setPoints(
        isAdmin && !PointsVisible
          ? "Infinite"
          : Valid
            ? Balance
              ? Balance
              : 0
            : 0
      );
      setStartedPoints(
        isAdmin && !PointsVisible ? "Infinite" : Valid ? StartedBalance : 0
      );

      setSpent(isAdmin && !PointsVisible ? "Infinite" : Valid ? Spent : 0);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { Balance, StartedBalance, isAdmin, Username, Valid } =
        await GetPoints();
      setUsername(Username);

      setPoints(
        isAdmin && !PointsVisible
          ? "Infinite"
          : Valid
            ? Balance
              ? Balance
              : 0
            : 0
      );
      setStartedPoints(
        isAdmin && !PointsVisible ? "Infinite" : Valid ? StartedBalance : 0
      );
    })();
  }, [PointsVisible]);

  useEffect(() => {
    (async () => {
      const { Valid } = await ValidateToken();

      if (!Valid) {
        SaveToken("");
        return navigate("/SignIn");
      }

      SaveCurrentPage("/");
    })();
  }, []);

  const [Popup, setPopup] = useState(false);
  const [EditPopup, setEditPopup] = useState(false);
  const [CategorytoEdit, setCategorytoEdit] = useState({});
  const [RefreshComp, setRefreshComp] = useState(false);

  const [open, setOpen] = useState(false);
  const [Message, setMessage] = useState(undefined);

  useEffect(() => {
    if (Message) {
      handleClick();
    } else {
      handleClose();
    }
  }, [Message]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage(undefined);
  };

  useEffect(() => {
    setTimeout(async () => {
      const Data = await GetLivesStatus();
      const { OfflineCount, OnlineCount, Valid } = Data ? Data : {};
      if (Valid) {
        setShowLive(true);
        setOnlineCount(OnlineCount);
        setOfflineCount(OfflineCount);
      }
    }, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const Data = await GetLivesStatus();
      const { OfflineCount, OnlineCount, Valid } = Data ? Data : {};
      if (Valid) {
        setShowLive(true);
        setOnlineCount(OnlineCount);
        setOfflineCount(OfflineCount);
      }
    })();
  }, []);
  return (
    // Make height 100% and adapt to the screen size
    <div
      style={{
        width: "100%",
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={Message?.Type}
          sx={{ width: "100%" }}
        >
          {Message?.Message}
        </Alert>
      </Snackbar>

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "90%",
            height: "100%",
            background: "lightgrey",
            paddingLeft: 10,
            paddingRight: 10,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <h2>Home</h2>
            <h3
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
                color: "red",
                fontWeight: "bold",
                fontSize: 20,
                position: "absolute",
                right: 50,
              }}
            >
              <h3 style={{ color: "black" }}>V</h3>2.2
            </h3>
          </div>

          <div
            style={{
              width: "60%",
              height: 60,
              backgroundColor: "lightgrey",
              marginTop: 20,
              opacity: Username <= 0 ? 0 : 1,
              transition: "all .2s",
            }}
          >
            <h3
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "center",
              }}
            >
              Welcome
              <h3 style={{ color: "red", marginLeft: 5 }}>{` ` + Username}</h3>,
              This is the home page
            </h3>
          </div>

          <div style={{ width: "100%", height: "75%", overflow: "scroll" }}>
            <div
              style={{
                width: "100%",
                marginTop: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Card
                style={{
                  backgroundColor: "black",
                  display: "flex",
                  width: 200,
                  height: 100,
                  marginLeft: 10,
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  marginTop: 10,
                  opacity: Points == undefined ? 0 : 1,
                  transition: "all .2s",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Your balance
                  </Typography>

                  <CardActionArea
                    onClick={() => {
                      setPointsVisible(!PointsVisible);
                    }}
                    style={{
                      width: 25,
                      height: 25,
                      marginRight: 10,
                      borderRadius: 25,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {PointsVisible ? (
                      <Visibility
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    )}
                  </CardActionArea>
                </div>

                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      marginLeft: 15,
                      marginTop: 10,
                      fontWeight: "bold",
                      color: Points <= AlertOnPoints ? "orange" : "white",
                    }}
                  >
                    {Points}
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 13,
                      fontWeight: "bolder",
                      color: "#4BC0D9",
                    }}
                  >
                    Points
                  </Typography>
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "black",
                  display: "flex",
                  width: 200,
                  height: 100,
                  marginLeft: 10,
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  marginTop: 10,
                  opacity: Points == undefined ? 0 : 1,
                  transition: "all .2s",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Started balance
                  </Typography>

                  <CardActionArea
                    onClick={() => {
                      setPointsVisible(!PointsVisible);
                    }}
                    style={{
                      width: 25,
                      height: 25,
                      marginRight: 10,
                      borderRadius: 25,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {PointsVisible ? (
                      <Visibility
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    ) : (
                      <VisibilityOff
                        style={{ width: 20, height: 20, color: "white" }}
                      />
                    )}
                  </CardActionArea>
                </div>

                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      marginLeft: 15,
                      marginTop: 10,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {StartedPoints}
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "rgb(75, 192, 217)",
                    }}
                  >
                    Points
                  </Typography>
                </div>
              </Card>

              <Card
                style={{
                  backgroundColor: "black",
                  display: "flex",
                  width: 200,
                  height: 100,
                  marginLeft: 10,
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  marginTop: 10,
                  opacity: Points == undefined ? 0 : 1,
                  transition: "all .2s",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Spent balance
                  </Typography>
                </div>

                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    marginLeft: 15,
                    marginTop: 10,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 15,
                      marginLeft: 15,
                      marginTop: 10,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {Spent}
                  </Typography>

                  <Typography
                    variant="h5"
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "rgb(75, 192, 217)",
                    }}
                  >
                    Points
                  </Typography>
                </div>
              </Card>

              {ShowLive && (
                <Card
                  style={{
                    backgroundColor: "green",
                    display: "flex",
                    width: 200,
                    height: 100,
                    marginLeft: 10,
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 10,
                    opacity: 1,
                    transition: "all .2s",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: 15,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      ONLINE
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      marginLeft: 15,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 15,
                        marginLeft: 15,
                        marginTop: 10,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {OnlineCount}
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 10,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      LIVES
                    </Typography>
                  </div>
                </Card>
              )}

              {ShowLive && (
                <Card
                  style={{
                    backgroundColor: "red",
                    display: "flex",
                    width: 200,
                    height: 100,
                    marginLeft: 10,
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    marginTop: 10,
                    opacity: 1,
                    transition: "all .2s",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginLeft: 15,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      OFFLINE
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      marginLeft: 15,
                      marginTop: 10,
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 15,
                        marginLeft: 15,
                        marginTop: 10,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {OfflineCount}
                    </Typography>

                    <Typography
                      variant="h5"
                      style={{
                        fontSize: 10,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      LIVES
                    </Typography>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
