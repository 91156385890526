export default {
  Navigation: [
    {
      Name: "Categories",
      Path: "Categories",
      permitted: false,
    },
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
    {
      Name: "Serials & Mac Addresses",
      Path: "SerialsAndMacs",
      permitted: false,
    },
    {
      Name: "Activated Clients",
      Path: "Clients",
      permitted: false,
    },
    {
      Name: "Users",
      Path: "Users",
      permitted: false,
    },
    {
      Name: "Series",
      Path: "Series",
      permitted: false,
    },
    {
      Name: "Series Episodes",
      Path: "SeriesEpisodes",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
    {
      Name: "Periods",
      Path: "Periods",
      permitted: false,
    },
    {
      Name: "Countries",
      Path: "Countries",
      permitted: false,
    },
    {
      Name: "Movies",
      Path: "Movies",
      permitted: false,
    },
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
    { Name: "Settings", Path: "Settings", permitted: false },
    {
      Name: "Servers",
      Path: "Servers",
      permitted: false,
    },
  ],
  Delete: [
    {
      Name: "Categories",
      Path: "Categories",
      permitted: false,
    },
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
    {
      Name: "Serials & Mac Addresses",
      Path: "SerialsAndMacs",
      permitted: false,
    },
    {
      Name: "Activated Clients",
      Path: "Clients",
      permitted: false,
    },
    {
      Name: "Users",
      Path: "Users",
      permitted: false,
    },
    {
      Name: "Series",
      Path: "Series",
      permitted: false,
    },
    {
      Name: "Series Episodes",
      Path: "SeriesEpisodes",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
    {
      Name: "Periods",
      Path: "Periods",
      permitted: false,
    },
    {
      Name: "Countries",
      Path: "Countries",
      permitted: false,
    },
    {
      Name: "Movies",
      Path: "Movies",
      permitted: false,
    },
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
    {
      Name: "Servers",
      Path: "Servers",
      permitted: false,
    },
  ],
  Edit: [
    {
      Name: "Categories",
      Path: "Categories",
      permitted: false,
    },
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
    {
      Name: "Serials & Mac Addresses",
      Path: "SerialsAndMacs",
      permitted: false,
    },
    {
      Name: "Activated Clients",
      Path: "Clients",
      permitted: false,
    },
    {
      Name: "Users",
      Path: "Users",
      permitted: false,
    },
    {
      Name: "Series",
      Path: "Series",
      permitted: false,
    },
    {
      Name: "Series Episodes",
      Path: "SeriesEpisodes",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
    {
      Name: "Periods",
      Path: "Periods",
      permitted: false,
    },
    {
      Name: "Movies",
      Path: "Movies",
      permitted: false,
    },
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
    { Name: "Settings", Path: "Settings", permitted: false },
    {
      Name: "Servers",
      Path: "Servers",
      permitted: false,
    },
  ],
  Upload: [
    {
      Name: "Serials & Mac Addresses",
      Path: "SerialsAndMacs",
      permitted: false,
    },
    {
      Name: "Series Episodes",
      Path: "SeriesEpisodes",
      permitted: false,
    },
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
  ],
  Generate: [
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
  ],
  Manual: [
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
  ],
  Create: [
    {
      Name: "Categories",
      Path: "Categories",
      permitted: false,
    },
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
    {
      Name: "Serials & Mac Addresses",
      Path: "SerialsAndMacs",
      permitted: false,
    },
    {
      Name: "Users",
      Path: "Users",
      permitted: false,
    },
    {
      Name: "Series",
      Path: "Series",
      permitted: false,
    },
    {
      Name: "Series Episodes",
      Path: "SeriesEpisodes",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
    {
      Name: "Periods",
      Path: "Periods",
      permitted: false,
    },
    {
      Name: "Movies",
      Path: "Movies",
      permitted: false,
    },
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
    {
      Name: "Servers",
      Path: "Servers",
      permitted: false,
    },
  ],
  Export: [
    {
      Name: "Codes",
      Path: "Codes",
      permitted: false,
    },
    {
      Name: "Sub Categories",
      Path: "SubCategories",
      permitted: false,
    },
  ],
  Status: [
    {
      Name: "Lives",
      Path: "Lives",
      permitted: false,
    },
  ],
};
